import React, { useState, useEffect } from "react"
import { loadCSS } from "fg-loadcss/src/loadCSS"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"

import { Container } from "@material-ui/core"

import hodgeContainerStyling from "./hodgecontainer.module.scss"
import layoutStyles from "../../components/layout/layout.module.scss"

import HodgeContainerFull from "./hodgeContainerFull"
import HodgeContainerMobile from "./hodgeContainerMobile"
import HodgeContainerCharts from "./hodgeContainerCharts"

const HodgeCapsule = () => {
  const [isMobileViewOn, setMobileView] = useState(false)
  const [isWideViewOn, setWideView] = useState(false)
  const [isChartsViewOn, setChartsView] = useState(true)
  const asOfDate = "08-Mar-2020"

  const mobileView = () => {
    setMobileView(true)
    setWideView(false)
    setChartsView(false)
  }
  const wideView = () => {
    setMobileView(false)
    setWideView(true)
    setChartsView(false)
  }
  const chartView = () => {
    setMobileView(false)
    setWideView(false)
    setChartsView(true)
  }

  useEffect(() => {
    loadCSS(
      "https://fonts.googleapis.com/icon?family=Material+Icons",
      document.querySelector("#insertion-point-jss")
    )
    return () => {}
  }, [])

  return (
    <div>
      <br />
      <Container className={hodgeContainerStyling.raisedBackgroundViewSelector}>
        <Row noGutters>
          <Col fluid="true">
            <Form>
              <span className={layoutStyles.heading}>
                <Form.Check
                  type="switch"
                  id="chart-switcher"
                  inline
                  tabIndex="0"
                  onChange={chartView}
                  checked={isChartsViewOn}
                  label="CHARTS"
                />{" "}
                <Form.Check
                  type="switch"
                  id="mobile-switcher"
                  inline
                  tabIndex="0"
                  onChange={mobileView}
                  checked={isMobileViewOn}
                  label="MOBILE"
                />
                <Form.Check
                  type="switch"
                  id="wide-switcher"
                  inline
                  tabIndex="0"
                  onChange={wideView}
                  checked={isWideViewOn}
                  label="WIDE VIEW"
                />
              </span>
            </Form>{" "}
          </Col>
          <Col sm={1}></Col>
          <Col fluid="true">
            <span className={hodgeContainerStyling.smallText}>
              3DWS Data Analytics as of {asOfDate}{" "}
            </span>
          </Col>{" "}
        </Row>
      </Container>
      <br />
      <>
        {isChartsViewOn ? (
          <HodgeContainerCharts />
        ) : isMobileViewOn ? (
          <HodgeContainerMobile />
        ) : isWideViewOn ? (
          <HodgeContainerFull />
        ) : (
          <HodgeContainerCharts />
        )}
      </>
    </div>
  )
}

export default HodgeCapsule
