import React from "react"
import Layout from "../../components/layout/layout"
import Head from "../../components/layout/head"
// import HodgeCapsule from "../../../stats/hodge/hodgeCapsule"
import HodgeContainerCharts from "../../stats/hodge/hodgeContainerCharts"
import HodgeFootnotes from "../../stats/hodge/hodgeFootnotes"
import QuoteBox from "../../components/layout/quoteBox"
import layoutStyles from "../../components/layout/layout.module.scss"

const D1HodgeStats = () => {
  return (
    <Layout>
      <Head
        pageTitle="Hodge Charts"
        pageDescription="Love Wrestling? Then you'll enjoy these stats about hodge trophy finalists and winners, or those wrestlers contending for the Hodge Trophy this season."
      />

      <h1 className={layoutStyles.heading}>HODGE CHARTS</h1>
      {/* <HodgeCapsule /> */}
      <HodgeContainerCharts />
      <HodgeFootnotes />
      <QuoteBox />
    </Layout>
  )
}

export default D1HodgeStats
