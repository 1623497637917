import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Head from "../components/layout/head"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { FaHome as IconHome } from "react-icons/fa"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
// import IconInsideChart from "@material-ui/icons/InsertChartOutlined"
import IconDollarSign from "@material-ui/icons/AttachMoney"
import HearingIcon from "@material-ui/icons/Hearing"
import wsStyles from "./ws.module.scss"

const Home = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      logo3DWS: file(name: { regex: "/3DWS_Short/gi" }) {
        name
        childImageSharp {
          fluid {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
      logo3DWSFull: file(name: { regex: "/3DWS_Full/gi" }) {
        name
        childImageSharp {
          fluid {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
  `)

  const rand = Math.random() < 0.5

  return (
    <div className={wsStyles.LandingBackground}>
      <Container>
        <Head
          pageTitle="3DWS"
          pageDescription="The Most Comprehensive Stats in Collegiate Style Wrestling. 15 minutes with 3D Wrestler Stats (3DWS) tools will save you at least 15 hours of video analysis and focus any video analysis you do. Do More with Stats! Do More For Wrestlers!"
        />
        {/* <span className={wsStyles.LandingBigText}> 3DWS</span> */}
        <Row className="justify-content-md-center">
          <Col xs={3} sm={3} lg={3} xl={3} md={3}>
            {rand ? (
              <Img
                fluid={imageQuery.logo3DWS.childImageSharp.fluid}
                alt="3D Wrestler Stats - 3DWS"
              >
                {" "}
              </Img>
            ) : (
              <Img
                fluid={imageQuery.logo3DWSFull.childImageSharp.fluid}
                alt="3D Wrestler Stats"
              >
                {" "}
              </Img>
            )}
          </Col>
        </Row>
        <br />
        <h4 className={wsStyles.LandingText}>
          Dive Into Wrestling Data Analytics
        </h4>
        <br />
        <Link className={wsStyles.LandingNavLink} to="/">
          &nbsp;&nbsp; <IconHome />
          &nbsp; HOME
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Link
          className={wsStyles.LandingNavLink}
          to="/wrestling-stats/hodge-charts/"
        >
          <TrendingUpIcon /> &nbsp;HODGE CHARTS
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Link className={wsStyles.LandingNavLink} to="/pricing">
          <IconDollarSign />
          PRICING
        </Link>
      </Container>
      <br />
      <Container>
        <Link
          className={wsStyles.LandingNavLink}
          to="/wrestling-stats/ncaa-d1-wrestling-stats-leaders/"
        >
          <HearingIcon />
          &nbsp; D1 STATS LEADERS
        </Link>
      </Container>
      <br />
    </div>
  )
}

export default Home
