import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../../../components/layout/layout"
import Card from "react-bootstrap/Card"
import CardColumns from "react-bootstrap/CardColumns"
import Badge from "react-bootstrap/Badge"
import IconCalendar from "@material-ui/icons/InsertInvitation"
import { FaTags as IconTags } from "react-icons/fa"

//import IconTags from "@material-ui/icons/Bookmark"
import Button from "react-bootstrap/Button"
import Head from "../../../components/layout/head"
import QuoteBox from "../../../components/layout/quoteBox"
import { OutboundLink } from "gatsby-plugin-gtag"
import layoutStyles from "../../../components/layout/layout.module.scss"

const HodgeBlog = () => {
  const blogPosts = useStaticQuery(graphql`
    query {
      allMdx(
        filter: {
          frontmatter: { published: { eq: true }, blog_type: { eq: "hodge" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            excerpt(pruneLength: 113)
            frontmatter {
              title
              date(formatString: "DD-MMM-YYYY")
              key
              keywords
              description
              blog_type
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Head
        pageTitle="Hodge Trophy Blog"
        pageDescription="Love Wrestling? Then you'll love our articles about hodge trophy winners and candidates."
      />
      <h1 className={layoutStyles.heading}>HODGE TROPHY BLOG</h1>
      <p className="lead">
        Posts and insights related to{" "}
        <OutboundLink href="https://www.win-magazine.com/win-awards/">
          {" "}
          WIN Magazine's
        </OutboundLink>{" "}
        <OutboundLink href="https://www.win-magazine.com/win-awards/hodge-trophy/">
          {" "}
          Hodge Trophy Award
        </OutboundLink>
        . You can find stats on these great wrestles on our{" "}
        <Link to="/wrestling-stats/hodge/hodge-trophy-wrestling-stats">
          Hodge Stats
        </Link>{" "}
        page.
      </p>
      <CardColumns>
        {blogPosts.allMdx.edges.map(({ node }) => {
          const { excerpt } = node

          const { slug } = node.fields
          const {
            key,
            blog_type,
            keywords,
            title,
            date,
            // description,
          } = node.frontmatter

          return (
            // <Card key={edge.node.frontmatter.key} style={{ width: "32rem" }}>
            <Card key={key} border="dark">
              <Card.Body>
                <Link to={`/blog/${blog_type}/${slug}`}>
                  <Card.Text as="h3">{title}</Card.Text>
                </Link>
                <Card.Text>{excerpt}</Card.Text>
                <Button variant="dark" href={`${slug}`}>
                  Read On ...
                </Button>
                <br />
                <br />
                <footer>
                  <small className="text-muted">
                    <IconCalendar /> &nbsp;Posted:&nbsp;
                    {date}
                  </small>
                  <br />
                  <small className="text-muted bold">
                    <IconTags /> &nbsp;Keywords:&nbsp;&nbsp;
                    {keywords.map(keyword => {
                      return (
                        <span key={keyword}>
                          <Badge pill variant="dark">
                            {keyword}
                          </Badge>{" "}
                        </span>
                      )
                    })}
                  </small>
                </footer>
              </Card.Body>
            </Card>
          )
        })}
      </CardColumns>
      <QuoteBox />
    </Layout>
  )
}

export default HodgeBlog
