import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import Head from "../components/layout/head"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import indexStyling from "./index.module.scss"
import layoutStyles from "../components/layout/layout.module.scss"
import QuoteBox from "../components/layout/quoteBox"
import CauliBadge from "../components/common/caulibadge"
import { OutboundLink } from "gatsby-plugin-gtag"

const IndexPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      file(name: { regex: "/statsWinChampionships/" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Head
        pageTitle="Home"
        pageDescription="Do More With Stats! 15 minutes with 3D Wrestler Stats tools will save you at least 15 hours of video analysis and focus any video analysis you do."
      />
      <div>
        <h1 className={layoutStyles.heading}>3D WRESTLER STATS</h1>
        <p className="lead">
          Your home for the latest and greatest data analytics for the wrestling
          world. Do More With Stats!
        </p>
        <Row>
          <Col lg={12} xs={12}>
            <CauliBadge />
          </Col>{" "}
        </Row>
        <Row>
          <Col lg={6} xs={12} className="p-3">
            <Container className={indexStyling.raisedContainer}>
              <h5>
                15 minutes with 3D Wrestler Stats tools will save you at least
                15 hours of video analysis and focus any video analysis you do.
              </h5>
              3D Wrestler{" "}
              <a
                href="https://3d-wrestler-stats.square.site/s/shop"
                alt="3D Wrestler Stats Store"
              >
                Stats Packages
              </a>{" "}
              Provide Data Analytics for Wrestler Performance:
              <ul>
                <li>
                  For Every Wrestler, Not Just the Few that Have Video Available
                </li>
                <li>
                  For All Aspects of this Great Sport (power ratings for top,
                  bottom, neutral, period-by-period, etc.)
                </li>
                <li>
                  In both Team &amp; Individual Wrestler formats (including
                  category &amp; performance leader-boards)
                </li>
              </ul>
            </Container>{" "}
          </Col>{" "}
          <Col lg={6} xs={12} className="p-3">
            <Container className={indexStyling.raisedContainer}>
              <h5>Designed to do more than just count number of takedowns.</h5>
              3D Wrestler{" "}
              <a
                href="https://3d-wrestler-stats.square.site/s/shop"
                alt="3D Wrestler Stats Store"
              >
                Stats Packages
              </a>
              :{" "}
              <ul>
                <li>
                  Utilize the same Data Analytics tools that businesses use to
                  monitor and predict system performance
                </li>
                <li>
                  Help any wrestler, at any level move towards the best version
                  of themselves
                </li>
                <li>
                  3D Wrestler Stats (3DWS) also analyzes the{" "}
                  <Link to="/wrestling-stats/hodge-charts/">
                    best D1 college wrestlers
                  </Link>{" "}
                  in the land
                </li>
              </ul>
            </Container>
          </Col>{" "}
        </Row>
        <br />
        <Row className="p-3">
          <Col />
          <Col lg={6} xs={12}>
            <h3 className={indexStyling.heading}>STATS WIN CHAMPIONSHIPS</h3>
            <p className="lead">
              A bit of a play on words as the counting of team points does, at
              the end of the day, determine an event's champion. Since this is
              probably only funny to statisticians or bean counters, the video
              explains a bit more about value{" "}
              <a
                href="https://3d-wrestler-stats.square.site/s/shop"
                alt="3D Wrestler Stats Store"
              >
                3D Wrestler Stats
              </a>{" "}
              offers to teams and coaches.
            </p>
            <OutboundLink href="https://youtu.be/GV3x56oRpl0">
              <Img
                fluid={imageQuery.file.childImageSharp.fluid}
                alt="Stats Win Championships"
              >
                {" "}
              </Img>
            </OutboundLink>{" "}
          </Col>
          <Col />
        </Row>{" "}
        <QuoteBox />
      </div>
    </Layout>
  )
}

export default IndexPage
