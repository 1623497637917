import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import Card from "react-bootstrap/Card"
import CardColumns from "react-bootstrap/CardColumns"
import Badge from "react-bootstrap/Badge"
import IconCalendar from "@material-ui/icons/InsertInvitation"
import { FaTags as IconTags } from "react-icons/fa"
import Form from "react-bootstrap/Form"

import StatsBlogIcon from "@material-ui/icons/DoubleArrow"
import HodgeBlogIcon from "@material-ui/icons/TerrainOutlined"

import Button from "react-bootstrap/Button"
import Head from "../../components/layout/head"
import QuoteBox from "../../components/layout/quoteBox"
import layoutStyles from "../../components/layout/layout.module.scss"

const BlogSearch = props => {
  const { data } = props
  const allPosts = data.allMdx.edges
  const emptyQuery = ""

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const handleInputChange = event => {
    // console.log(event.target.value)
    const query = event.target.value
    const { data } = props

    // this is how we get all of our posts
    const posts = data.allMdx.edges || []

    // return all filtered posts
    const filteredData = posts.filter(post => {
      // destructure data from post frontmatter
      const { keywords, title, description } = post.node.frontmatter

      return (
        // standardize data with .toLowerCase()
        // return true if the description, title or tags
        // contains the query string
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) ||
        (keywords &&
          keywords
            .join("") // convert tags from an array to string
            .toLowerCase()
            .includes(query.toLowerCase()))
      )
    })
    // update state according to the latest query and results
    setState({
      query, // with current query string from the `Input` event
      filteredData, // with filtered data from posts.filter(post => (//filteredData)) above
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts

  return (
    <Layout>
      <Head
        pageTitle="3DWS BLOG SEARCH"
        pageDescription="Love Stats? Love Searching for stuff? Then you're in the right place."
      />
      <h1 className={layoutStyles.heading}>3DWS BLOG SEARCH</h1>
      <p className="lead">
        A page showing all of 3DWS' posts. You can filter below to find posts
        that interest you. If you'd prefer to review the different blog pages
        directly, click below:
      </p>
      <ul>
        <li>
          {" "}
          <Link to="/blog/wrestling-stats/wrestling-stats-blog">
            <StatsBlogIcon />
            &nbsp;STATS BLOG
          </Link>
        </li>
        <li>
          {" "}
          <Link to="/blog/hodge/hodge-trophy-wrestling-blog">
            <HodgeBlogIcon />
            &nbsp;HODGE BLOG
          </Link>
        </li>
      </ul>
      <h2 className={layoutStyles.heading}>SEARCH</h2>
      <br />
      <Form.Group controlId="blogFilter">
        <Form.Control
          type="email"
          placeholder="Type to filter posts ... "
          onChange={handleInputChange}
        />
      </Form.Group>
      <br />
      <CardColumns>
        {posts.map(({ node }) => {
          // const { excerpt } = node

          const { slug } = node.fields
          const {
            key,
            blog_type,
            keywords,
            title,
            date,
            description,
          } = node.frontmatter

          let descExcerpt = description.substring(0, 113).concat("...")
          // console.log(descExcerpt)
          // {blogPosts.allMarkdownRemark.edges.map(edge => {
          return (
            // <Card key={edge.node.frontmatter.key} style={{ width: "32rem" }}>
            <Card key={key} text="white" bg="dark" border="info">
              <Card.Body>
                {/* <Link to={`blog/wrestling-stats/${edge.node.fields.slug}`}> */}
                <Link to={`/blog/${blog_type}/${slug}`}>
                  <Card.Text as="h3">{title}</Card.Text>
                </Link>
                <Card.Text>{descExcerpt}</Card.Text>
                <Button variant="light" href={`/blog/${blog_type}/${slug}`}>
                  Read On ...
                </Button>
                <br />
                <br />
                <footer>
                  <small className="text-muted">
                    <IconCalendar /> &nbsp;Posted:&nbsp;
                    {date}
                  </small>
                  <br />
                  <small className="text-muted bold">
                    <IconTags /> &nbsp;Keywords:&nbsp;&nbsp;
                    {keywords.map(keyword => {
                      return (
                        <span key={keyword}>
                          <Badge pill variant="secondary">
                            {keyword}
                          </Badge>{" "}
                        </span>
                      )
                    })}
                  </small>
                </footer>
              </Card.Body>
            </Card>
          )
        })}
      </CardColumns>
      <QuoteBox />
    </Layout>
  )
}

export default BlogSearch

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD-MMM-YYYY")
            key
            keywords
            description
            blog_type
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
