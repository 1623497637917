import logo from "../../../static/logos/3DWS_Full_64x64.png"
import logoShort from "../../../static/logos/3DWS_Short_64x64.png"
import React from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import headerStyles from "./header.module.scss"
import { FaHome as IconHome } from "react-icons/fa"

import BlogSearchIcon from "@material-ui/icons/Search"
//import StatsBlogIcon from "@material-ui/icons/CompareArrows"
import StatsBlogIcon from "@material-ui/icons/DoubleArrow"

import IconShoppingAdd from "@material-ui/icons/AddShoppingCart"
// import IconDollarSign from "@material-ui/icons/AttachMoney"
import LockOpenIcon from "@material-ui/icons/LockOpen"
//import IconBooks from "@material-ui/icons/LibraryBooks"
//import IconChart from "@material-ui/icons/BarChart"
// import IconInsideChart from "@material-ui/icons/InsertChartOutlined"
import TableChartIcon from "@material-ui/icons/TableChart"
// import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
// import BarChartIcon from "@material-ui/icons/BarChart"
import HearingIcon from "@material-ui/icons/Hearing"
import IconContacts from "@material-ui/icons/Contacts"
import IconAnnouncement from "@material-ui/icons/Announcement"
import IconPinnacle from "@material-ui/icons/TerrainOutlined"
//import IconFitnessCenter from "@material-ui/icons/FitnessCenterOutlined"

function Header() {
  const rand = Math.random() < 0.5

  return (
    <Navbar bg="dark" variant="dark" expand="xl">
      <Navbar.Brand>
        <Link to="/3dws">
          {rand ? (
            <img
              src={logo}
              alt="3D Wrestler Stats Logo"
              className="d-inline-block align-top"
            />
          ) : (
            <img
              src={logoShort}
              alt="3D Wrestler Stats Logo"
              className="d-inline-block align-top"
            />
          )}{" "}
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" as="ul">
          &nbsp; &nbsp;
          <NavDropdown.Divider />
          <Nav.Item as="li">
            &nbsp;{" "}
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/"
            >
              <IconHome />
              &nbsp; HOME
            </Link>
            &nbsp; &nbsp;
          </Nav.Item>
          &nbsp;
          {/* blog/blog-search/ */}
          <Nav.Item as="li">
            &nbsp;{" "}
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/blog/blog-search"
            >
              <BlogSearchIcon />
              &nbsp;BLOG SEARCH
            </Link>
            &nbsp; &nbsp;
          </Nav.Item>
          &nbsp;
          <Nav.Item as="li">
            &nbsp;{" "}
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/blog/wrestling-stats/wrestling-stats-blog"
            >
              <StatsBlogIcon />
              &nbsp;STATS BLOG
            </Link>
            &nbsp; &nbsp;
          </Nav.Item>
          &nbsp;
          <Nav.Item as="li">
            &nbsp;{" "}
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/blog/hodge/hodge-trophy-wrestling-blog"
            >
              <IconPinnacle />
              &nbsp;HODGE BLOG
            </Link>
            &nbsp; &nbsp;
          </Nav.Item>
          <NavDropdown.Divider />
          &nbsp;
          <Nav.Item as="li">
            &nbsp;{" "}
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/wrestling-stats/hodge-charts/"
            >
              <TrendingUpIcon /> &nbsp;CHARTS
            </Link>
            &nbsp; &nbsp;
          </Nav.Item>{" "}
          &nbsp;
          <Nav.Item as="li">
            &nbsp;{" "}
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/wrestling-stats/hodge-stats/"
            >
              <TableChartIcon /> &nbsp;STATS
            </Link>
            &nbsp; &nbsp;
          </Nav.Item>{" "}
          &nbsp;
          <Nav.Item as="li">
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/wrestling-stats/ncaa-d1-wrestling-stats-leaders/"
            >
              <HearingIcon />
              &nbsp; D1 STATS
            </Link>
            &nbsp;
          </Nav.Item>
          &nbsp;
          <NavDropdown.Divider />
          &nbsp;
          <Nav.Item as="li">
            &nbsp;{" "}
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/pricing"
            >
              <LockOpenIcon />
              &nbsp; PRICING
            </Link>
            &nbsp; &nbsp;
          </Nav.Item>
          &nbsp;
          <Nav.Item as="li">
            &nbsp;{" "}
            <a
              href="https://3d-wrestler-stats.square.site/s/shop"
              className={headerStyles.NavLink}
            >
              <IconShoppingAdd />
              &nbsp; SHOP
            </a>{" "}
            &nbsp; &nbsp;
          </Nav.Item>
          &nbsp;
          <Nav.Item as="li">
            &nbsp;{" "}
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/about"
            >
              <IconAnnouncement /> &nbsp; ABOUT
            </Link>
            &nbsp; &nbsp;
          </Nav.Item>
          &nbsp;
          <Nav.Item as="li">
            &nbsp;{" "}
            <Link
              className={headerStyles.NavLink}
              activeClassName={headerStyles.ActiveNavItem}
              to="/contact"
            >
              <IconContacts /> &nbsp;CONTACT
            </Link>
            &nbsp; &nbsp;
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
export default Header
