import React from "react"
import Card from "react-bootstrap/Card"
import footerStyles from "./footer.module.scss"
import { OutboundLink } from "gatsby-plugin-gtag"
import { FaTwitter } from "react-icons/fa"

const Footer = () => {
  return (
    <span className={footerStyles.FooterMargin}>
      <Card className="text-center">
        <Card.Footer className="text-muted">
          Copyright, 3D Wrestler Stats 2019&copy;, All Rights Reserved.
          <div>
            {" "}
            Reach out via{" "}
            <OutboundLink href="https://twitter.com/3DWrestlerStats">
              Twitter <FaTwitter />
            </OutboundLink>{" "}
            to talk wrestling stats or just talk smack.
            <div>
              {" "}
              Sign-up in our{" "}
              <OutboundLink href="https://3d-wrestler-stats.square.site/s/shop">
                Online Store (by Square)
              </OutboundLink>
            </div>
          </div>
          {/* <div>
            <a href="#!" onClick={window.Metomic("ConsentManager:show")}>
              Manage Cookie Settings
            </a>
          </div> */}
        </Card.Footer>
      </Card>
    </span>
  )
}

export default Footer
