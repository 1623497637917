import React from "react"
import { Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import IconContact from "@material-ui/icons/PermIdentity"
import IconCalendar from "@material-ui/icons/InsertInvitation"
import { FaTags as IconTags } from "react-icons/fa"
//import IconTags from "@material-ui/icons/Bookmark"
import Head from "../components/layout/head"
import QuoteBox from "../components/layout/quoteBox"
import Badge from "react-bootstrap/Badge"
import markDownStyling from "./blog-post-module.scss"

export const blogPostQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "D-MMM-YYYY")
        author
        keywords
        blog_type
      }
    }
  }
`

const Blog = props => {
  let bType = String(props.data.mdx.frontmatter.blog_type)
  let isWrestlingStats = true
  bType === String("wrestling-stats")
    ? (isWrestlingStats = true)
    : (isWrestlingStats = false)

  return (
    <Layout>
      <Head pageTitle={props.data.mdx.frontmatter.title} />
      <h1>{props.data.mdx.frontmatter.title}</h1>
      <small className=" text-muted">
        <IconCalendar /> &nbsp;Posted:&nbsp;
        {props.data.mdx.frontmatter.date} &nbsp;&nbsp;|&nbsp;&nbsp;
        <IconContact /> &nbsp;Author:&nbsp;
        {props.data.mdx.frontmatter.author}
      </small>
      <br />
      <small className="text-muted bold">
        <IconTags /> &nbsp;Keywords:&nbsp;&nbsp;
        <>
          {isWrestlingStats
            ? props.data.mdx.frontmatter.keywords.map(keyword => {
                return (
                  <span key={keyword} id={keyword}>
                    <Link to="/blog/wrestling-stats/wrestling-stats-blog">
                      <Badge pill variant="info">
                        {keyword}
                      </Badge>{" "}
                    </Link>{" "}
                  </span>
                )
              })
            : props.data.mdx.frontmatter.keywords.map(keyword => {
                return (
                  <span key={keyword} id={keyword}>
                    <Link to="/blog/hodge/hodge-trophy-wrestling-blog">
                      <Badge pill variant="dark">
                        {keyword}
                      </Badge>{" "}
                    </Link>{" "}
                  </span>
                )
              })}
        </>
      </small>{" "}
      <br />
      <br />
      <div className={markDownStyling}>
        <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
      </div>
      <QuoteBox />
    </Layout>
  )
}

export default Blog
