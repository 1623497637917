import React from "react"
import Layout from "../components/layout/layout"
import Head from "../components/layout/head"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-gtag"
import IconCamera from "@material-ui/icons/PhotoCamera"

import { FaTwitter } from "react-icons/fa"
import { FaFacebook } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FaEnvelopeOpenText } from "react-icons/fa"
import { FaLinkedin } from "react-icons/fa"
import layoutStyles from "../components/layout/layout.module.scss"

const Contact = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      file(name: { regex: "/Contact/" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Head
        pageTitle="Contact"
        pageDescription="How to Connect and Communicate with 3D Wrestler Stats. Welcome to the Party and Enjoy the Show!"
      />
      <h1 className={layoutStyles.heading}>CONNECT WITH US!</h1>
      <Row>
        <Col lg={6} xs={12}>
          <Card border="secondary" className="p-3">
            <Card.Body>
              <span className="lead">
                3DWS on the SOCIAL AIR WAVES:
                <ul className="h3">
                  <li>
                    <OutboundLink href="https://twitter.com/3DWrestlerStats">
                      <FaTwitter /> Twitter
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink href="https://www.facebook.com/3DWrestlerStats/">
                      <FaFacebook /> Facebook
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink href="https://www.instagram.com/3dwrestlerstats/">
                      <FaInstagram /> Instagram
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink href="https://www.youtube.com/channel/UCse5FL65ikT2V_pvaChjz7A">
                      <FaYoutube /> YouTube
                    </OutboundLink>
                  </li>
                  <li>
                    <a href="mailto: 3dwrestlerstats@gmail.com?subject=3DWS-Let's Make Contact">
                      <FaEnvelopeOpenText /> 3dwrestlerstats@gmail.com
                    </a>
                  </li>
                  <li>
                    <OutboundLink href="https://www.linkedin.com/company/3d-wrestler-stats">
                      <FaLinkedin /> LinkedIn
                    </OutboundLink>
                  </li>
                </ul>
              </span>{" "}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} xs={12}>
          {" "}
          <Card border="secondary" className="p-3">
            <Card.Body>
              <h4 className={layoutStyles.heading}>MAKE CONTACT!</h4>
              <OutboundLink href="https://twitter.com/3DWrestlerStats">
                <Img
                  fluid={imageQuery.file.childImageSharp.fluid}
                  alt="Make Contact"
                >
                  {" "}
                </Img>
              </OutboundLink>
              <footer>
                <small className="text-muted">
                  <IconCamera />
                  &nbsp;Photo by:&nbsp;{" "}
                  <OutboundLink href="https://unsplash.com/photos/5QIPyaDT1V0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                    Solal Ohayon
                  </OutboundLink>{" "}
                  on{" "}
                  <OutboundLink href="https://unsplash.com/search/photos/contact?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                    Unsplash
                  </OutboundLink>
                </small>
              </footer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default Contact
