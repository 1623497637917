import React, { useState } from "react"
import { graphql } from "gatsby"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { useTable, useSortBy } from "react-table"

import Layout from "../../components/layout/layout"
import Head from "../../components/layout/head"
import HodgeFootnotes from "../../stats/hodge/hodgeFootnotes"
import QuoteBox from "../../components/layout/quoteBox"
import layoutStyles from "../../components/layout/layout.module.scss"

export const hodgeStatQuery = graphql`
  query {
    hodgeDominance: allWrestlerDataJson {
      edges {
        node {
          HodgeRank
          wname
          year
          wins
          losses
          tper
          dominance
          match_length_avg
          matches_full_length
          bonus_predictor
          fall_predictor
          mp_per_7_minutes
          mper
          td_predictor
          td_per_7_minutes
          escrev_predictor
          rt_predictor
        }
      }
    }
    hodgeDataYears: allWrestlerDataJson {
      edges {
        node {
          year
        }
      }
    }
    hodgeStatYears: allWrestlerDataJson {
      group(field: year, limit: 1) {
        edges {
          node {
            year
          }
        }
      }
    }
    colDataDominance: allHeaderDataJson(
      filter: {
        accessor: { in: ["HodgeRank", "wname", "year", "wins", "losses"] }
      }
    ) {
      edges {
        node {
          Header
          accessor
        }
      }
    }
    hodgeRecord: allWrestlerDataJson {
      edges {
        node {
          HodgeRank
          wname
          wschool
          year
          wins
          losses
          fall_total
          falls_vs_ranked
          tech_falls
          majors
          bonus_predictor
          fall_predictor
        }
      }
    }
    hodgeQOC: allWrestlerDataJson {
      edges {
        node {
          year
          wname
          HodgeRank
          wschool
          wins
          losses
          matches_vs_ranked
          wins_vs_ranked
          losses_vs_ranked
          bonus_vs_ranked
          bonus_predictor_vs_ranked
          falls_vs_ranked
          matches_vs_aa
          wins_vs_aa
          losses_vs_aa
          bonus_vs_aa
          bonus_predictor_vs_aa
        }
      }
    }
  }
`

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy)

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  const firstPageRows = rows.slice(0, 20)

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* <div>Showing the first 20 results of {rows.length} rows</div> */}
    </>
  )
}

const HodgeStats = props => {
  // let qYear = 2020

  // const hodgeDominanceData = props.data.hodgeDominance.edges
  //   .map(wrestlers => wrestlers.node)
  //   .filter(wrestler => wrestler.HodgeRank !== 99)
  // // .filter(wrestler => wrestler.year === qYear && wrestler.HodgeRank !== 99)

  // const hodgeRecordData = props.data.hodgeRecord.edges
  //   .map(wrestlers => wrestlers.node)
  //   .filter(wrestler => wrestler.HodgeRank !== 99)

  // const hodgeQualityOfCompetitionData = props.data.hodgeQOC.edges
  //   .map(wrestlers => wrestlers.node)
  //   .filter(wrestler => wrestler.HodgeRank !== 99)

  const [hodgeYear, setHodgeYear] = useState("Year...")
  const [hodgeDomData, setHodgeDomData] = useState([])
  //   hodgeDominanceData.filter(wrestler => wrestler.year === hodgeYear)
  // )
  const [hodgeRecData, setHodgeRecData] = useState([])
  //   hodgeRecordData.filter(wrestler => wrestler.year === hodgeYear)
  // )
  const [hodgeQOCData, setHodgeQOCData] = useState([])
  // hodgeQualityOfCompetitionData.filter(
  //   wrestler => wrestler.year === hodgeYear
  // )

  const [hodgeDominanceData, setHodgeDominanceData] = useState([])
  const [hodgeRecordData, setHodgeRecordData] = useState([])
  const [
    hodgeQualityOfCompetitionData,
    setHodgeQualityOfCompetitionData,
  ] = useState([])
  // let hodgeDominanceData,
  //   hodgeRecordData,
  //   hodgeQualityOfCompetitionData = []

  React.useEffect(() => {
    const fetchData = async () => {
      const domD = await props.data.hodgeDominance.edges
        .map(wrestlers => wrestlers.node)
        .filter(wrestler => wrestler.HodgeRank !== 99)
      // .filter(wrestler => wrestler.year === qYear && wrestler.HodgeRank !== 99)

      const recD = await props.data.hodgeRecord.edges
        .map(wrestlers => wrestlers.node)
        .filter(wrestler => wrestler.HodgeRank !== 99)

      const qocD = await props.data.hodgeQOC.edges
        .map(wrestlers => wrestlers.node)
        .filter(wrestler => wrestler.HodgeRank !== 99)

      setHodgeDominanceData(domD)
      setHodgeRecordData(recD)
      setHodgeQualityOfCompetitionData(qocD)
    }
    fetchData()
    // hodgeDataYear(qYear)
  }, [
    props.data.hodgeDominance.edges,
    props.data.hodgeRecord.edges,
    props.data.hodgeQOC.edges,
  ])

  // Update the selected year
  const hodgeDataYear = year => {
    setHodgeYear(year)

    setHodgeDomData(
      hodgeDominanceData.filter(wrestler => wrestler.year === year * 1)
    )
    setHodgeRecData(
      hodgeRecordData.filter(wrestler => wrestler.year === year * 1)
    )
    setHodgeQOCData(
      hodgeQualityOfCompetitionData.filter(
        wrestler => wrestler.year === year * 1
      )
    )
  }

  // console.log(hodgeYear)
  // console.log(JSON.stringify(hodgeDomData))

  const hodgeYearData = props.data.hodgeDataYears.edges.map(
    wrestlers => wrestlers.node
  )

  //get unique set of years from hodge Data & sort in Descending Order
  const hodgeStatYearList = [
    ...new Set(
      hodgeYearData
        .map(x => x.year)
        .sort(function(a, b) {
          return b - a
        })
    ),
  ]

  hodgeStatYearList.unshift("Year...")
  // console.log(JSON.stringify(hodgeStatYearList))

  const hodgeDominanceColumns = [
    { Header: "Year", accessor: "year" },
    { Header: "Wrestler", accessor: "wname" },
    { Header: "Rank", accessor: "HodgeRank" },
    { Header: "Wins", accessor: "wins" },
    { Header: "Losses", accessor: "losses" },
    { Header: "Dom.", accessor: "dominance", sortType: "basic" },
    { Header: "Bonus %", accessor: "bonus_predictor", sortType: "basic" },
    { Header: "Fall %", accessor: "fall_predictor", sortType: "basic" },
    {
      Header: "Avg ML",
      accessor: "match_length_avg",
      sortType: "basic",
    },
    { Header: "Ft Mt", accessor: "matches_full_length" },
    {
      Header: "MP/7M",
      accessor: "mp_per_7_minutes",
      sortType: "basic",
    },
    { Header: "TD %", accessor: "td_predictor", sortType: "basic" },
    {
      Header: "TD/7M",
      accessor: "td_per_7_minutes",
      sortType: "basic",
    },
    { Header: "E1/R2 %", accessor: "escrev_predictor", sortType: "basic" },
    { Header: "RT %", accessor: "rt_predictor", sortType: "basic" },
  ]

  const hodgeRecordColumns = [
    { Header: "Year", accessor: "year" },
    { Header: "Wrestler", accessor: "wname" },
    { Header: "Rank", accessor: "HodgeRank" },
    { Header: "Wins", accessor: "wins" },
    { Header: "Losses", accessor: "losses" },
    { Header: "Falls", accessor: "fall_total", sortType: "basic" },
    { Header: "Falls vs Ranked", accessor: "falls_vs_ranked" },
    { Header: "MD", accessor: "majors" },
    { Header: "TF", accessor: "tech_falls" },
    { Header: "Bonus %", accessor: "bonus_predictor", sortType: "basic" },
    { Header: "Fall %", accessor: "fall_predictor", sortType: "basic" },
  ]

  const hodgeQOCColumns = [
    {
      Header: "Wrestler Info",
      columns: [
        { Header: "Year", accessor: "year" },
        { Header: "Wrestler", accessor: "wname" },
        { Header: "School", accessor: "wschool" },
        { Header: "Rank", accessor: "HodgeRank" },
      ],
    },
    {
      Header: "vs. Ranked Opponents",
      columns: [
        { Header: "Matches", accessor: "matches_vs_ranked" },
        { Header: "Wins", accessor: "wins_vs_ranked" },
        { Header: "Losses", accessor: "losses_vs_ranked" },
        { Header: "Bonus", accessor: "bonus_vs_ranked" },
        {
          Header: "Bonus %",
          accessor: "bonus_predictor_vs_ranked",
          sortType: "basic",
        },
        { Header: "Falls", accessor: "falls_vs_ranked" },
      ],
    },
    {
      Header: "vs. All Americans",
      columns: [
        { Header: "Matches", accessor: "matches_vs_aa" },
        { Header: "Wins", accessor: "wins_vs_aa" },
        { Header: "Losses", accessor: "losses_vs_aa" },
        { Header: "Bonus", accessor: "bonus_vs_aa" },
        {
          Header: "Bonus %",
          accessor: "bonus_predictor_vs_aa",
          sortType: "basic",
        },
      ],
    },
  ]

  // console.log(JSON.stringify(hodgeRecordColumns))

  const domColumns = React.useMemo(() => hodgeDominanceColumns, [
    hodgeDominanceColumns,
  ])
  const domData = React.useMemo(() => hodgeDomData, [hodgeDomData])

  const recColumns = React.useMemo(() => hodgeRecordColumns, [
    hodgeRecordColumns,
  ])
  const recData = React.useMemo(() => hodgeRecData, [hodgeRecData])

  const qocColumns = React.useMemo(() => hodgeQOCColumns, [hodgeQOCColumns])
  const qocData = React.useMemo(() => hodgeQOCData, [hodgeQOCData])

  return (
    <Layout>
      <Head
        pageTitle="Hodge Stats"
        pageDescription="Love Wrestling? Then you'll enjoy these stats about hodge trophy finalists and winners, or those wrestlers contending for the Hodge Trophy this season."
      />
      <h1 className={layoutStyles.heading}>HODGE STATS TABLES</h1>
      <>
        <Form>
          <Form.Group as={Row} controlId="hodgeStatsForm.Year">
            <Col xs={3} sm={2} md={2} lg={1}>
              <Form.Label>Hodge Year</Form.Label>
            </Col>
            <Col xs={3} sm={2} md={2} lg={1}>
              <Form.Control
                as="select"
                onChange={event => hodgeDataYear(event.target.value)}
                value={hodgeYear}
              >
                {hodgeStatYearList.map(year => (
                  <option key={year}>{year}</option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Form>
      </>
      <h2 className={layoutStyles.heading}>DOMINANCE</h2>
      <Table columns={domColumns} data={domData} />
      <h2 className={layoutStyles.heading}>RECORD</h2>
      <Table columns={recColumns} data={recData} />
      <h2 className={layoutStyles.heading}>QUALITY of COMPETITION</h2>
      <Table columns={qocColumns} data={qocData} />
      <HodgeFootnotes />
      <QuoteBox />
    </Layout>
  )
}

export default HodgeStats
