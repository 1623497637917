import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-gtag"
import Layout from "../components/layout/layout"
import Head from "../components/layout/head"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import IconCamera from "@material-ui/icons/PhotoCamera"
import { FaHome as IconHome } from "react-icons/fa"
import BlogSearchIcon from "@material-ui/icons/Search"
import IconInsideChart from "@material-ui/icons/InsertChartOutlined"
import HearingIcon from "@material-ui/icons/Hearing"

const NotFound = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      compassA: file(name: { regex: "/CompassA_/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      compassB: file(name: { regex: "/CompassB_/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      compassC: file(name: { regex: "/CompassC_/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      compassD: file(name: { regex: "/CompassD_/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      compassE: file(name: { regex: "/CompassE_/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const picAttribution = {
    compassA: {
      photographer: "Alan J Hurt",
      photographerURL: "https://unsplash.com/@alanaut24",
      photoURL: "https://unsplash.com/photos/T8tL9_1DRWA",
      photoDescription: "Cloud Covered Machu Picchu",
    },
    compassB: {
      photographer: "Brenan Greene",
      photographerURL: "https://unsplash.com/@frontstreetmedia",
      photoURL: "https://unsplash.com/photos/rMWral7lvD0",
      photoDescription: "Mount Shasta",
    },
    compassC: {
      photographer: "Bayo Adegunloye",
      photographerURL: "https://unsplash.com/@beewhy001",
      photoURL: "https://unsplash.com/photos/PMJa4bno7NM",
      photoDescription: "Rowing in Scotland",
    },
    compassD: {
      photographer: "Daniel Seßler",
      photographerURL: "https://unsplash.com/@danielsessler",
      photoURL: "https://unsplash.com/photos/0HQIpY3YcJA",
      photoDescription: "Lighthouse at Ynys llanddwyn, UK",
    },
    compassE: {
      photographer: "Bayo Adegunloye",
      photographerURL: "https://unsplash.com/@beewhy001",
      photoURL: "https://unsplash.com/photos/ALGaFWrgTcw",
      photoDescription: "Zaanse Schans, Zaandam, Netherlands",
    },
  }

  let photographer, photographerURL, photoURL, photoDescription
  let picNum = getRndInteger(0, 4)
  // console.log("pictNum Randomizer: " + picNum)

  if (picNum === 0) {
    ;({
      photographer,
      photographerURL,
      photoURL,
      photoDescription,
    } = picAttribution.compassA)
  } else if (picNum === 1) {
    ;({
      photographer,
      photographerURL,
      photoURL,
      photoDescription,
    } = picAttribution.compassB)
  } else if (picNum === 2) {
    ;({
      photographer,
      photographerURL,
      photoURL,
      photoDescription,
    } = picAttribution.compassC)
  } else if (picNum === 3) {
    ;({
      photographer,
      photographerURL,
      photoURL,
      photoDescription,
    } = picAttribution.compassD)
  } else if (picNum === 4) {
    ;({
      photographer,
      photographerURL,
      photoURL,
      photoDescription,
    } = picAttribution.compassE)
  } else {
    ;({
      photographer,
      photographerURL,
      photoURL,
      photoDescription,
    } = picAttribution.compassA)
  }

  // console.log(picNum)
  // console.table({ photographer, photographerURL, photoURL, photoDescription })

  return (
    <Layout>
      <Head
        pageTitle="404-Page Not Found"
        pageDescription="These are not the Wrestling Stats You're Looking For. Return home for more details."
      />
      <h1>404-Error-We can't find the page you're looking for</h1>
      <Row>
        <Col lg={4} xs={12} className="p-3">
          <Card border="secondary">
            <Card.Body>
              <h4>Here are some links that might help</h4>
              <h5>
                {" "}
                <Link to="/">
                  <IconHome />
                  &nbsp;HOME
                </Link>
              </h5>
              <h5>
                {" "}
                <Link to="/blog/blog-search">
                  <BlogSearchIcon />
                  &nbsp;BLOG SEARCH
                </Link>
              </h5>
              <h5>
                {" "}
                <Link to="/wrestling-stats/hodge-charts/">
                  <IconInsideChart /> &nbsp;HODGE CHARTS
                </Link>
              </h5>
              <h5>
                {" "}
                <Link to="/wrestling-stats/ncaa-d1-wrestling-stats-leaders/">
                  <HearingIcon />
                  &nbsp; D1 STATS LEADERS
                </Link>
              </h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8} xs={12} className="p-3">
          <Card border="secondary">
            <Card.Body>
              <>
                {(() => {
                  switch (picNum) {
                    case 0:
                      return (
                        <Link to="/">
                          <Img
                            fluid={imageQuery.compassA.childImageSharp.fluid}
                            alt={photoDescription}
                          >
                            {" "}
                          </Img>
                        </Link>
                      )
                    case 1:
                      return (
                        <Link to="/">
                          <Img
                            fluid={imageQuery.compassB.childImageSharp.fluid}
                            alt={photoDescription}
                          >
                            {" "}
                          </Img>
                        </Link>
                      )
                    case 2:
                      return (
                        <Link to="/">
                          <Img
                            fluid={imageQuery.compassC.childImageSharp.fluid}
                            alt={photoDescription}
                          >
                            {" "}
                          </Img>
                        </Link>
                      )
                    case 3:
                      return (
                        <Link to="/">
                          <Img
                            fluid={imageQuery.compassD.childImageSharp.fluid}
                            alt={photoDescription}
                          >
                            {" "}
                          </Img>
                        </Link>
                      )
                    case 4:
                      return (
                        <Link to="/">
                          <Img
                            fluid={imageQuery.compassE.childImageSharp.fluid}
                            alt={photoDescription}
                          >
                            {" "}
                          </Img>
                        </Link>
                      )
                    default:
                      return (
                        <Link to="/">
                          <Img
                            fluid={imageQuery.compassA.childImageSharp.fluid}
                            alt={photoDescription}
                          >
                            {" "}
                          </Img>
                        </Link>
                      )
                  }
                })()}
              </>
              <footer>
                <small className="text-muted">
                  <IconCamera />
                  &nbsp;{photoDescription} by:&nbsp;{" "}
                  <OutboundLink href={photographerURL}>
                    {photographer}
                  </OutboundLink>{" "}
                  on <OutboundLink href={photoURL}>Unsplash</OutboundLink>
                </small>
              </footer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default NotFound
