import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-gtag"
import { FaTwitter as IconTwitter } from "react-icons/fa"
import layoutStyles from "../../components/layout/layout.module.scss"

const HodgeFootnotes = () => {
  return (
    <div>
      <br />
      <br />
      <h4 className={layoutStyles.heading}>HODGE STATS FOOTNOTES</h4>
      <p className="lead">
        This page contains details about some of the best D1 college wrestlers
        to have been considered for or to have won the Hodge Trophy. Read more
        about our approach to stats-based data analytics in wrestling and
        articles about Hodge Trophy winners on our{" "}
        <Link to="/blog/hodge/hodge-trophy-wrestling-blog">D1 Hodge Blog</Link>.
        Additional details and breakdowns are available on{" "}
        <OutboundLink href="https://drive.google.com/open?id=1XOYHPgDCaRsfX681wcWy8zHp1MWb5anO">
          Google Docs
        </OutboundLink>
        .
      </p>
      <p>
        *Please note that the 3D Wrestler Status WAPRS© tools works best when
        match action details (takedowns, by whom, choice, by whom, etc.) can be
        identified.{" "}
      </p>
      <h5 className={layoutStyles.heading}>*P4P RANKINGS FROM:</h5>
      <ul>
        <li>
          WS:{" "}
          <a href="https://www.wrestlestat.com/rankings/hodge">
            WrestleStat Hodge Watch
          </a>{" "}
          (only available for current year){" "}
        </li>
        <li>
          WP:{" "}
          <a href="https://wrestlingbypirate.wordpress.com/?s=Dual+Impact+Index">
            Wrestling by Pirate P4P Ratings within the Dual Impact Index
          </a>
        </li>
        <li>
          Flo:{" "}
          <a href="https://www.flowrestling.org/rankings">Flowrestling P4P</a>
        </li>
      </ul>
      <h5 className={layoutStyles.heading}>*3DWS DEFINITIONS:</h5>
      <ul>
        <li>
          HCR (Hodge Contender Rating), a Predictive Model designed to consider
          the statistics focused{" "}
          <OutboundLink href="https://www.win-magazine.com/win-awards/hodge-trophy/">
            {" "}
            WIN Magazine' Hodge Criteria
          </OutboundLink>{" "}
          and predict, based on current season performance and past credentials
          how each wrestler will fair at the end of the season.{" "}
        </li>
        <li>
          TPER (Team Point Efficiency Rating), or Dominance as a %. Dominance is
          the NCAA's standard factor for calculating win types - standard dual
          meet team points scored / matches wrestled. The 3DWS Team Point
          Efficiency Rating (TPER) is the Dominance factor translated to a
          percentage. This was done to have a common percentage based factor for
          each rating.{" "}
        </li>
        <li>
          ML % (Match Length Percentage), the average match length as a
          percentage. If the Average match length is 3.5 minutes. The ML % will
          be 0.500.
        </li>
        <li>
          NF/F % (Nearfall / Fall Percentage), the percentage of times in the
          top position when the wrestler scores either a nearfall or a pin.
        </li>
        <li>
          PDI (Point Differential Index), which is out of 22 points, is based on
          work by Kyle Klingman & Andy Hamilton. See this article in WIN
          Magazine by Mike Finn{" "}
          <a href="https://www.win-magazine.com/2018/10/klingman-margin-of-victory-should-have-a-bigger-team-impact/">
            Klingman: Margin of victory should have a bigger team impact
          </a>{" "}
          for details.{" "}
        </li>
        <li>MP/7M (Match Points per 7 Minutes Wrestled) </li>{" "}
        <li>
          TD/7M (Takedowns per 7 Minutes Wrestled) based on work by{" "}
          <a href="https://twitter.com/LemonsAndPie">Brian Jones</a>. See this
          article in Black Shoe Diaries by{" "}
          <a href="https://www.twitter.com/JpPearson71">JP Pearson</a>:{" "}
          <a href="https://www.blackshoediaries.com/2019/3/5/18249273/penn-state-wrestling-phat-mat-stats-statistics-lemonpie-nolf-nickal-cassar-nick-lee-mark-hall">
            Phat Mat Stats is Digging Deeper into Bout-Level Statistics with
            Lemons and Pie
          </a>{" "}
          for details.
        </li>
        <li>Avg ML (Average Match Length) displayed in minutes:seconds </li>{" "}
        <li>
          Ft Mt (Fulltime Matches), the number of matches that went the full 7
          Minutes or into Overtime
        </li>
      </ul>
      <h5 className={layoutStyles.heading}>3DWS TOOLS FOR YOUR TEAM:</h5>
      <p>
        Take Full Advantage of our Wrestling Analytics for your team by
        signing-up today in our{" "}
        <a href="https://3d-wrestler-stats.square.site/s/shop">
          Online Store (by Square)
        </a>
        . Message (dm) us on{" "}
        <a href="https://twitter.com/3DWrestlerStats">
          Twitter <IconTwitter />
        </a>{" "}
        with any questions.
      </p>
    </div>
  )
}

export default HodgeFootnotes
