import React from "react"
import Layout from "../components/layout/layout"
import Head from "../components/layout/head"
import pricingStyles from "./pricing.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Quotebox from "../components/layout/quoteBox"
import layoutStyles from "../components/layout/layout.module.scss"
import LockOpenIcon from "@material-ui/icons/LockOpen"
// import TransformIcon from "@material-ui/icons/Transform"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import SpeedIcon from "@material-ui/icons/Speed"

const Pricing = () => {
  return (
    <Layout>
      <Head
        pageTitle="Pricing"
        pageDescription="The Most Comprehensive Stats in Collegiate Style Wrestling. 15 minutes with 3D Wrestler Stats (3DWS) tools will save you at least 15 hours of video analysis and focus any video analysis you do. Do More with Stats! Do More For Wrestlers!"
      />
      <h1 className={layoutStyles.heading}>PRICING</h1>
      <h4>
        Want to deepen your understanding of and unlock your Wrestlers' Best
        Performance?
      </h4>
      <br />
      <Container className={pricingStyles.raisedBackground}>
        <Row>
          <Col xs={12}>
            <>
              <div>
                <h3>
                  Stop sifting through data and scouring hours of video ...{" "}
                </h3>
                <h4>
                  <SpeedIcon /> Saves Time & Refocuses video analysis work
                </h4>
                <h4>
                  <LockOpenIcon /> Unlocks Your Wrestlers' Full Potential
                </h4>
                <h4>
                  <TrendingUpIcon /> Transforms your Team
                </h4>
              </div>
            </>
            <p className="lead">
              View our offerings below or move directly to our{" "}
              <a href="https://3d-wrestler-stats.square.site/s/shop">
                Online Store (by Square)
              </a>
            </p>
          </Col>
        </Row>
      </Container>
      <br />
      <div className={pricingStyles.demo10}>
        <Container className={pricingStyles.raisedBackground}>
          <Row>
            <Col md={3} sm={6} xs={12} className="p-3">
              <div className={pricingStyles.pricingTable10}>
                <div className={pricingStyles.pricingTableHeader}>
                  <h3 className={pricingStyles.heading}>Free Team Scorecard</h3>
                  <span className={pricingStyles.priceValue}>
                    <span className={pricingStyles.currency}>$</span> 0{" "}
                    {/* <span className={pricingStyles.month}></span> */}
                  </span>
                </div>
                <div className={pricingStyles.pricingContent}>
                  <ul>
                    <li>Aggregate TW Data</li>
                    <li>Free 1x per Season for Starters</li>
                    <li>Use Discount Code: "FreeYourTeam"</li>
                    <li>Team Dashboard & Scorecard</li>
                    <li>Team's Biggest Needs at a Glance</li>
                    <li>Refine & Re-align Coaching Time & Energies</li>
                  </ul>
                  <a
                    className={pricingStyles.read}
                    href="https://3d-wrestler-stats.square.site/product/3dws-team-scorecard-free-each-season/3?cp=true&sa=true&sbp=false&q=false"
                    alt="3D Wrestler Store - Team Scorecard"
                  >
                    sign up
                  </a>
                </div>
              </div>
            </Col>
            <Col md={3} sm={6} xs={12} className="p-3">
              <div className={pricingStyles.pricingTable10}>
                <div className={pricingStyles.pricingTableHeader}>
                  <h3 className={pricingStyles.heading}>
                    Round-by-Round - Full Season
                  </h3>
                  <span className={pricingStyles.priceValue}>
                    <span className={pricingStyles.currency}>$</span> 150{" "}
                    {/* <span className={pricingStyles.month}>1x</span> */}
                  </span>
                </div>
                <div className={pricingStyles.pricingContent}>
                  <ul>
                    <li>Round-by-Round analysis</li>
                    <li>8-12 wrestlers of similar caliber</li>
                    <li>Team reports shows tendencies for the program</li>
                    <li>Find overlooked strengths or weaknesses</li>
                    <li>Scorecards for Wrestlers</li>
                    <li>
                      All matches included in win counts, e.g., Forfeit and MFF
                      wins
                    </li>
                    <li>PREREQUISITES:</li>
                    <li>
                      Match Action Records must be in TrackWrestling's OPC or on
                      Flo Arena to be included
                    </li>
                  </ul>
                  <a
                    className={pricingStyles.read}
                    href="https://3d-wrestler-stats.square.site/product/2020-21-round-by-round-full-season/10?cp=true&sa=true&sbp=false&q=false"
                    alt="3D Wrestler Store - Round-by-Round - Full Season"
                  >
                    sign up
                  </a>
                </div>
              </div>
            </Col>
            <Col md={3} sm={6} xs={12} className="p-3">
              <div className={pricingStyles.pricingTable10}>
                <div className={pricingStyles.pricingTableHeader}>
                  <h3 className={pricingStyles.heading}>
                    RxR Predictive Modeling
                  </h3>
                  <span className={pricingStyles.priceValue}>
                    <span className={pricingStyles.currency}>$</span> 50
                    {/* <span className={pricingStyles.month}>1x</span> */}
                  </span>
                </div>
                <div className={pricingStyles.pricingContent}>
                  <ul>
                    <li>Round-by-Round analysis</li>
                    <li>8-12 wrestlers</li>
                    <li>8-12 matches per wrestler</li>
                    <li>Team reports shows tendencies for the program</li>
                    <li>Find overlooked strengths or weaknesses</li>
                    <li>Scorecards for Wrestlers</li>
                    <li>PREREQUISITES:</li>
                    <li>
                      Matches Wrestled will be analyzed, e.g., no Forfeits or
                      MFF in win counts
                    </li>
                    <li>
                      Match Action Records must be in TrackWrestling's OPC or on
                      Flo Arena to be included
                    </li>
                  </ul>
                  <a
                    className={pricingStyles.read}
                    href="https://3d-wrestler-stats.square.site/product/2020-21-predictive-modeling/9?cp=true&sa=true&sbp=false&q=false"
                    alt="3D Wrestler Store - Round-by-Round - Predictive Modeling"
                  >
                    sign up
                  </a>
                </div>
              </div>
            </Col>
            <Col md={3} sm={6} xs={12} className="p-3">
              <div className={pricingStyles.pricingTable10}>
                <div className={pricingStyles.pricingTableHeader}>
                  <h3 className={pricingStyles.heading}>
                    Aggregate <br />
                    Analysis
                  </h3>
                  <span className={pricingStyles.priceValue}>
                    <span className={pricingStyles.currency}>$</span> 50{" "}
                    {/* <span className={pricingStyles.month}>1x </span> */}
                  </span>
                </div>
                <div className={pricingStyles.pricingContent}>
                  <ul>
                    <li>Aggregate Reports</li>
                    <li>Requires TrackWrestling's Statistics Program</li>
                    <li>Pulls Aggregate Data designated wrestlers</li>
                    <li>Team Dashboard & Scorecard </li>
                    <li>Scorecards for Starting Wrestlers</li>
                    <li>Does not contain Round-by-Round Information</li>
                    <li>PREREQUISITES:</li>
                    <li>
                      Match Action Records must be in TrackWrestling's OPC
                    </li>
                  </ul>
                  <a
                    className={pricingStyles.read}
                    href="https://3d-wrestler-stats.square.site/product/2020-21-aggregate-analysis/11?cp=true&sa=true&sbp=false&q=false"
                    alt="3D Wrestler Stats - Aggregate Analysis"
                  >
                    sign up
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <br />
      <p className="lead">
        15 minutes with 3D Wrestler Stats tools will save you at least 15 hours
        of video analysis and focus any video analysis you do.
      </p>
      <ul>
        <li>Re-Focus and Align During the Season</li>
        <li>Re-Invigorate During the Off-Season</li>
        <li>All while Guiding Individual Wrestlers to Greater Success</li>
      </ul>
      View our{" "}
      <a href="https://3d-wrestler-stats.square.site/s/shop">
        Online Store (by Square)
      </a>
      <br />
      <Quotebox />
    </Layout>
  )
}

export default Pricing
