import React from "react"
import Layout from "../../components/layout/layout"
import Head from "../../components/layout/head"
import CauliContainerCharts from "../../stats/cauli/cauliContainerCharts"
import CauliFootnotes from "../../stats/cauli/cauliFootnotes"
import QuoteBox from "../../components/layout/quoteBox"
import layoutStyles from "../../components/layout/layout.module.scss"

const CauliStats = () => {
  return (
    <Layout>
      <Head
        pageTitle="NCAA D1 Wrestling Stats Leaders"
        pageDescription="Love Wrestling? Then enjoy this view of d1 wrestling stats leaders by your Wrestling Friend (CauliFriend on Twitter)"
      />

      <h1 className={layoutStyles.heading}>NCAA D1 WRESTLING STATS LEADERS</h1>
      <CauliContainerCharts />
      {/* <HodgeContainerFull /> */}
      <CauliFootnotes />
      <QuoteBox />
    </Layout>
  )
}

export default CauliStats
