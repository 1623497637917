import React, { useReducer, useEffect } from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import { Container } from "@material-ui/core"

import { OutboundLink } from "gatsby-plugin-gtag"
import Chart from "react-google-charts"
import { isMobile } from "react-device-detect"

import layoutStyles from "../../components/layout/layout.module.scss"
import hodgeContainerStyling from "../hodge/hodgecontainer.module.scss"

import { loadCSS } from "fg-loadcss/src/loadCSS"

const wrestlerStatTypes = [
  {
    value: "falls",
    display: "Falls",
    subHeadText: "Your Individual NCAA D1 Fall Leaders. (average fall time)",
    type: "wrestlers",
    color: "#16609f",
    // range:
  },
  {
    value: "techfalls",
    display: "Technical Falls",
    subHeadText:
      "Tech Falls are a skill unto themselves. Who's leading NCAA D1 in this category? (Bonus Pct)",
    type: "wrestlers",
    color: "#9abaec",
  },
  {
    value: "dominance",
    display: "Dominance",
    subHeadText:
      "Who Leads in NCAA D1 Dominance Rating? (Falls / Tech Falls / Major Decisions)",
    type: "wrestlers",
    color: "#81bef3",
  },
  {
    value: "bonus",
    display: "Bonus %",
    subHeadText:
      "Who has the highest bonus %, i.e., Bonus Rate = (Bonus Wins) / (Possible Bonus Wins, i.e., Matches where bonus is possible)? (Falls / Tech Falls / Major Decisions)",
    type: "wrestlers",
    color: "#81bef3",
  },
  {
    value: "mper",
    display: "Match Point Efficiency (non-fall matches)",
    subHeadText:
      "Who's not only scoring, but also outscoring their opponents in non-fall matches? (pts for & against)",
    type: "wrestlers",
    color: "#8ccafe",
  },
]

const teamStatTypes = [
  {
    value: "falls",
    display: "Falls",
    subHeadText:
      "Which Roster is getting the most falls? (% of D1 non-forfeit matches)",
    type: "team",
    color: "#bf2c48",
  },
  {
    value: "techfalls",
    display: "Technical Falls",
    subHeadText:
      "Which team's roster leads the country in Tech Falls? (% of D1 non-forfeit matches)",
    type: "team",
    color: "#cf1616",
  },
]

function getChartTitle(data, type) {
  // console.log("getting Chart Title: " + data + " " + type)
  if (type === "wrestlers") {
    let chartTitleItem = wrestlerStatTypes.filter(e => e.value === data)
    let chartTitleText = chartTitleItem[0].subHeadText
    return chartTitleText
  } else {
    let chartTitleItem = teamStatTypes.filter(e => e.value === data)
    let chartTitleText = chartTitleItem[0].subHeadText
    return chartTitleText
  }
}

function getBarColor(data, type) {
  // console.log("getting Bar Color: " + data + " " + type)
  if (type === "wrestlers") {
    let chartTitleItem = wrestlerStatTypes.filter(e => e.value === data)
    let chartBarColor = chartTitleItem[0].color
    // console.log("here's your Bar Color: " + chartBarColor)
    return chartBarColor
  } else {
    let chartTitleItem = teamStatTypes.filter(e => e.value === data)
    let chartBarColor = chartTitleItem[0].color
    // console.log("here's your Bar Color: " + chartBarColor)
    return chartBarColor
  }
}

function getChartData(passedWrestlerSetID, passedDataSetID) {
  let chartMilterer = []
  chartMilterer = chartAllDataSets.filter(
    elem =>
      elem.chartWrestlerSet === passedWrestlerSetID &&
      elem.chartDataSet === passedDataSetID
  )

  chartMilterer = chartMilterer[0].chartData
  // console.log("getting Chart Data: " + JSON.stringify(chartMilterer))
  return chartMilterer
}

function getPixelValue(props) {
  if (props < 750) {
    return "600px"
  } else {
    return "1000px"
  }
}

const defaultChartTitle = "Your Individual NCAA D1 Fall Leaders"
const defaultChartData = [
  ["Wrestler", "Falls", { role: "style" }],
  ["1. Jacob Schwarm (Northern Iowa) (2:04)", 16, "#4B116F"],
  ["2. Ben Darmstadt (Cornell) (1:42)", 14, "#B31B1B"],
  ["3. Matt Stencel (Central Michigan) (2:01)", 14, "#6A0032"],
  ["4. Tanner Cook (SDSU) (2:55)", 12, "#0033A0"],
  ["5. Cody Surratt (Air Force) (2:05)", 11, "#003087"],
  ["6. Noah Gosner (Campbell) (2:43)", 11, "#EA7125"],
  ["7. Denton Spencer (Virginia) (1:37)", 10, "#232D4B"],
  ["8. Mason Parris (Michigan) (1:59)", 10, "#00274C"],
  ["9. Matthew Waddell (Chattanooga) (2:01)", 10, "#00386B"],
  ["10. Randy Meneweather II (Air Force) (2:06)", 10, "#003087"],
  ["11. Josh Mason (Bloomsburg) (2:29)", 10, "#8E2344"],
  ["12. Mark Hall (Penn State) (3:02)", 10, "#093162"],
  ["13. Jason Kraisser (Campbell) (3:03)", 10, "#EA7125"],
  ["14. Colston DiBlasi (George Mason) (3:45)", 10, "#006633"],
  ["15. Bryce West (Northern Illinois) (1:42)", 9, "#BA0C2F"],
  ["16. Jonathan Ross (Lock Haven) (2:06)", 9, "#A50034"],
  ["17. Anthony Mantanona (Oklahoma) (2:39)", 9, "#841617"],
  ["18. Brandon Womack (Cornell) (2:43)", 9, "#B31B1B"],
  ["19. Spencer Berthhold (Kent State) (2:53)", 9, "#002664"],
  ["20. Greg Bulsak (Clarion) (2:55)", 9, "#003366"],
  ["21. Cameron Caffey (Michigan State) (3:17)", 9, "#18453B"],
  ["22. Mitch McKee (Minnesota) (1:56)", 8, "#7A0019"],
  ["23. Benjamin Root (American) (2:10)", 8, "#ED193A"],
  ["24. Alex Esposito (Rutgers) (2:25)", 8, "#CC0033"],
  ["25. Cameron Valdiviez (Missouri) (2:28)", 8, "#F1B82D"],
  ["26. Jaret Lane (Lehigh) (2:32)", 8, "#653819"],
  ["27. Matt Kazimir (Columbia) (2:45)", 8, "#003865"],
  ["28. Zach Hartman (Bucknell) (2:58)", 8, "#E87722"],
  ["29. Quinn Miller (Virginia) (2:59)", 8, "#232D4B"],
  ["30. Reece Heller (Hofstra) (3:34)", 8, "#003591"],
  ["31. Alex Marinelli (Iowa) (3:45)", 8, "#FFCD00"],
  ["32. Drew Hildebrandt (Central Michigan) (4:22)", 8, "#6A0032"],
  ["33. Ethan Karsten (American) (1:28)", 7, "#ED193A"],
  ["34. Cary Miller (App. State) (1:29)", 7, "#FFCC00"],
  ["35. Tony Cassioppi (Iowa) (1:56)", 7, "#FFCD00"],
  ["36. Kellen Ekern (Old Dominion) (2:29)", 7, "#003057"],
  ["37. Ryan Cloud (Rider) (2:42)", 7, "#981E32"],
  ["38. John McGarry (VMI) (3:08)", 7, "#AE122A"],
  ["39. Andrew Fogarty (NDSU) (3:26)", 7, "#0A5640"],
  ["40. Taylor Lujan (Northern Iowa) (3:28)", 7, "#4B116F"],
  ["41. Michael Burchell (App. State) (3:34)", 7, "#FFCC00"],
  ["42. Fischer Cian (Davidson) (3:53)", 7, "#AC1A2F"],
  ["43. Luke Werner (Lock Haven) (4:08)", 7, "#A50034"],
  ["44. Shane Griffith (Stanford) (4:26)", 7, "#8C1515"],
  ["45. Chad Red (Nebraska) (1:17)", 6, "#E41C38"],
  ["46. Mitch Moore (Virginia Tech) (1:36)", 6, "#630031"],
  ["47. Tanner Harvey (American) (1:45)", 6, "#ED193A"],
  ["48. Jere Heino (Campbell) (1:49)", 6, "#EA7125"],
  ["49. Hayden Hidlay (NC State) (1:52)", 6, "#CC0000"],
  ["50. Jonathan Loew (Cornell) (1:53)", 6, "#B31B1B"],
  ["51. Logan Brown (Army) (1:57)", 6, "#FFD530"],
  ["52. Alec Hagan (Ohio) (2:09)", 6, "#00694E"],
  ["53. Zach Schrader (Cal Baptist) (2:19)", 6, "#002554"],
  ["54. Evan Cheek (Cleveland State) (2:23)", 6, "#006747"],
  ["55. Landon Pelham (Central Michigan) (2:26)", 6, "#6A0032"],
  ["56. Evan Wick (Wisconsin) (2:35)", 6, "#C5050C"],
  ["57. Jackson Turley (Rutgers) (2:41)", 6, "#CC0033"],
  ["58. Kayne Hutchison (Air Force) (2:46)", 6, "#003087"],
  ["59. Tyler Eischens (Stanford) (2:47)", 6, "#8C1515"],
  ["60. Daniel Bullard (NC State) (2:49)", 6, "#CC0000"],
  ["61. Hayden Hastings (Wyoming) (2:54)", 6, "#FFC425"],
  ["62. Bryce Andonian (Virginia Tech) (2:56)", 6, "#630031"],
  ["63. Brock Bergelin (Central Michigan) (2:58)", 6, "#6A0032"],
  ["64. Spencer Trenary (SDSU) (2:59)", 6, "#0033A0"],
  ["65. Kyle Mosher (Columbia) (3:07)", 6, "#003865"],
  ["66. Jordie White (The Citadel) (3:09)", 6, "#3975B7"],
  ["67. Ebed Jarrell (Drexel) (3:16)", 6, "#003F87"],
  ["68. Joe Manchio (Columbia) (3:18)", 6, "#003865"],
  ["69. Sage Heller (Hofstra) (3:32)", 6, "#003591"],
  ["70. Bryan McLaughlin (Drexel) (3:33)", 6, "#003F87"],
  ["71. Brett Donner (Rutgers) (3:38)", 6, "#CC0033"],
  ["72. Austin O'Connor (North Carolina) (3:40)", 6, "#7BAFD4"],
]

const chartAllDataSets = [
  {
    chartWrestlerSet: "wrestlers",
    chartDataSet: "dominance",
    chartData: [
      ["Wrestler", "Dominance", { role: "style" }],
      ["1. Mark Hall (Penn State) (10/3/3)", 4.7, "#093162"],
      ["2. Pat Glory (Princeton) (4/9/4)", 4.54, "#FF671F"],
      ["3. Spencer Lee (Iowa) (4/9/3)", 4.5, "#FFCD00"],
      ["4. Gable Steveson (Minnesota) (4/1/6)", 4.33, "#7A0019"],
      ["5. Nick Lee (Penn State) (5/7/4)", 4.29, "#093162"],
      ["6. Carter Starocci (Penn State) (4/2/4)", 4.25, "#093162"],
      ["7. Kollin Moore (Ohio State) (3/6/10)", 4.19, "#BB0000"],
      ["8. Shane Griffith (Stanford) (7/-/7)", 4.17, "#8C1515"],
      ["9. Mason Parris (Michigan) (10/1/6)", 4.14, "#00274C"],
      ["10. Hayden Hidlay (NC State) (6/5/9)", 4.11, "#CC0000"],
      ["11. Ryan Deakin (Northwestern) (5/-/8)", 4.1, "#4E2A84"],
      ["12. Jack Mueller (Virginia) (4/3/3)", 4.07, "#232D4B"],
      ["13. Alex Marinelli (Iowa) (8/1/2)", 4.05, "#FFCD00"],
      ["14. Carson Kharchla (Ohio State) (2/2/2)", 4, "#BB0000"],
      ["15. Noah Adams (West Virginia) (5/2/5)", 3.97, "#002855"],
      ["16. Real Woods (Stanford) (4/3/5)", 3.94, "#8C1515"],
      ["17. Vincenzo Joseph (Penn State) (5/1/4)", 3.94, "#093162"],
      ["18. Matthew Kolodzik (Princeton) (1/1/3)", 3.89, "#FF671F"],
      ["19. Austin O'Connor (North Carolina) (6/2/6)", 3.88, "#7BAFD4"],
      ["20. Tanner Cook (SDSU) (12/1/1)", 3.86, "#0033A0"],
      ["21. Nic Piccininni (OK State) (4/9/5)", 3.85, "#FF7300"],
      ["22. Michael Kemerer (Iowa) (3/3/4)", 3.81, "#FFCD00"],
      ["23. Seth Gross (Wisconsin) (4/7/8)", 3.76, "#C5050C"],
      ["24. Luke Pletcher (Ohio State) (3/2/11)", 3.67, "#BB0000"],
      ["25. Matt Stencel (Central Michigan) (14/-/3)", 3.64, "#6A0032"],
      ["26. Jordan Kutler (Lehigh) (4/1/6)", 3.64, "#653819"],
      ["27. Taylor Lujan (Northern Iowa) (7/1/5)", 3.57, "#4B116F"],
      ["28. Aaron Brooks (Penn State) (3/1/3)", 3.5, "#093162"],
      ["29. Sean Harman (Missouri) (1/-/-)", 3.5, "#F1B82D"],
      ["30. Bryce Steiert (Northern Iowa) (4/4/11)", 3.46, "#4B116F"],
      ["31. Mickey Phillipi (Pittsburgh) (2/1/6)", 3.31, "#1C2957"],
      ["32. Hunter Bolen (Virginia Tech) (2/2/4)", 3.31, "#630031"],
      ["33. David Carr (Iowa State) (2/2/5)", 3.3, "#C8102E"],
      ["34. Jake Ferri (Kent State) (3/3/2)", 3.29, "#002664"],
      ["35. Drew Hildebrandt (Central Michigan) (8/-/7)", 3.29, "#6A0032"],
      ["36. Boo Lewallen (OK State) (3/2/8)", 3.29, "#FF7300"],
      ["37. Roman Bravo-Young (Penn State) (1/3/6)", 3.29, "#093162"],
      ["38. Pat Lugo (Iowa) (2/1/3)", 3.24, "#FFCD00"],
      ["39. Cary Miller (App. State) (7/3/8)", 3.24, "#FFCC00"],
      ["40. Noah Gosner (Campbell) (11/1/2)", 3.21, "#EA7125"],
      ["41. Andrew Fogarty (NDSU) (7/2/4)", 3.18, "#0A5640"],
      ["42. Ben Darmstadt (Cornell) (14/3/3)", 3.18, "#B31B1B"],
      ["43. Chris Cannon (Northwestern) (4/-/5)", 3.18, "#4E2A84"],
      ["44. Brandon Paetzell (Lehigh) (3/4/3)", 3.17, "#653819"],
      ["45. Travis Wittlake (OK State) (1/3/4)", 3.15, "#FF7300"],
      ["46. Joe Lee (Penn State) (3/3/2)", 3.14, "#093162"],
      ["47. Tony Cassioppi (Iowa) (7/-/3)", 3.13, "#FFCD00"],
      ["48. Charles Tucker (Cornell) (2/-/1)", 3.13, "#B31B1B"],
      ["49. Jesse Dellavecchia (Rider) (1/2/7)", 3.13, "#981E32"],
      ["50. Sammy Sasso (Ohio State) (5/4/4)", 3.11, "#BB0000"],
    ],
  },
  {
    chartWrestlerSet: "wrestlers",
    chartDataSet: "bonus",
    chartData: [
      ["Wrestler", "Bonus %", { role: "style" }],
      ["1. Spencer Lee (Iowa) (4/9/3)", 0.941, "#FFCD00"],
      ["2. Mark Hall (Penn State) (10/3/3)", 0.773, "#093162"],
      ["3. Nick Lee (Penn State) (5/7/4)", 0.762, "#093162"],
      ["4. Pat Glory (Princeton) (4/9/4)", 0.739, "#FF671F"],
      ["5. Gable Steveson (Minnesota) (4/1/6)", 0.733, "#7A0019"],
      ["6. Kollin Moore (Ohio State) (3/6/10)", 0.731, "#BB0000"],
      ["7. Hayden Hidlay (NC State) (6/5/9)", 0.714, "#CC0000"],
      ["8. Jack Mueller (Virginia) (4/3/3)", 0.714, "#232D4B"],
      ["9. Nic Piccininni (OK State) (4/9/5)", 0.692, "#FF7300"],
      ["10. Bryce Steiert (Northern Iowa) (4/4/11)", 0.679, "#4B116F"],
      ["11. Real Woods (Stanford) (4/3/5)", 0.667, "#8C1515"],
      ["12. Ryan Moore (Cornell) (3/1/2)", 0.667, "#B31B1B"],
      ["13. Kevon Davenport (Nebraska) (3/1/-)", 0.667, "#E41C38"],
      ["14. Seth Gross (Wisconsin) (4/7/8)", 0.655, "#C5050C"],
      ["15. Ben Darmstadt (Cornell) (14/3/3)", 0.645, "#B31B1B"],
      ["16. Tanner Cook (SDSU) (12/1/1)", 0.636, "#0033A0"],
      ["17. Luke Werner (Lock Haven) (7/7/7)", 0.636, "#A50034"],
      ["18. Vincenzo Joseph (Penn State) (5/1/4)", 0.625, "#093162"],
      ["19. Carter Starocci (Penn State) (4/2/4)", 0.625, "#093162"],
      ["20. Michael Kemerer (Iowa) (3/3/4)", 0.625, "#FFCD00"],
      ["21. Phillip Moomey (Cornell) (2/2/1)", 0.625, "#B31B1B"],
      ["22. Ryan Deakin (Northwestern) (5/-/8)", 0.619, "#4E2A84"],
      ["23. Bennett Paulson (Army) (4/7/2)", 0.619, "#FFD530"],
      ["24. Boo Lewallen (OK State) (3/2/8)", 0.619, "#FF7300"],
      ["25. Joe Lee (Penn State) (3/3/2)", 0.615, "#093162"],
      ["26. Mason Parris (Michigan) (10/1/6)", 0.607, "#00274C"],
      ["27. Quentin Perez (Campbell) (5/6/6)", 0.607, "#EA7125"],
      ["28. Cary Miller (App. State) (7/3/8)", 0.6, "#FFCC00"],
      ["29. Christian Colucci (Rutgers) (3/-/3)", 0.6, "#CC0033"],
      ["30. Austin DeSanto (Iowa) (2/6/4)", 0.6, "#FFCD00"],
      ["31. Alec Opsal (Air Force) (2/1/-)", 0.6, "#003087"],
      ["32. Vince Turk (Iowa) (1/2/3)", 0.6, "#FFCD00"],
      ["33. Michael Beard (Penn State) (-/6/3)", 0.6, "#093162"],
      ["34. Trey Sizemore (Illinois) (-/1/1)", 0.6, "#13294B"],
      ["35. Luke Pletcher (Ohio State) (3/2/11)", 0.593, "#BB0000"],
      ["36. Andrew Fogarty (NDSU) (7/2/4)", 0.591, "#0A5640"],
      ["37. Shane Griffith (Stanford) (7/-/7)", 0.583, "#8C1515"],
      ["38. Evan Wick (Wisconsin) (6/3/6)", 0.577, "#C5050C"],
      ["39. Jackson Turley (Rutgers) (6/2/-)", 0.571, "#CC0033"],
      ["40. Dalton Peters (Nebraska) (3/1/-)", 0.571, "#E41C38"],
      ["41. Austin O'Connor (North Carolina) (6/2/6)", 0.56, "#7BAFD4"],
      ["42. Nino Bonaccorsi (Pittsburgh) (3/1/11)", 0.556, "#1C2957"],
      ["43. Robert Patrick (Virginia) (1/2/2)", 0.556, "#232D4B"],
      ["44. Matthew Kolodzik (Princeton) (1/1/3)", 0.556, "#FF671F"],
      ["45. Michael North (Maryland) (5/3/3)", 0.55, "#E03A3E"],
      ["46. Cameron Sykora (NDSU) (2/7/2)", 0.55, "#0A5640"],
      ["47. Bryce Andonian (Virginia Tech) (6/1/5)", 0.546, "#630031"],
      ["48. Taye Ghadiali (Campbell) (3/1/2)", 0.546, "#EA7125"],
      ["49. AJ Alford (The Citadel) (3/1/2)", 0.546, "#3975B7"],
      ["50. Brody Lamb (N. Colorado) (5/1/1)", 0.539, "#013C65"],
      ["51. Tanner Sloan (SDSU) (4/2/8)", 0.539, "#0033A0"],
      ["52. Michael Russo (Cornell) (4/2/1)", 0.539, "#B31B1B"],
      ["53. Sebastian Rivera (Northwestern) (2/-/5)", 0.539, "#4E2A84"],
      ["54. Gabriel Tagg (North Carolina) (1/4/2)", 0.539, "#7BAFD4"],
      ["55. Jacob Schwarm (Northern Iowa) (16/-/-)", 0.533, "#4B116F"],
      ["56. Chris Cannon (Northwestern) (4/-/5)", 0.529, "#4E2A84"],
      ["57. Brandon Womack (Cornell) (9/-/2)", 0.524, "#B31B1B"],
      ["58. Alex Marinelli (Iowa) (8/1/2)", 0.524, "#FFCD00"],
      ["59. Zach Schrader (Cal Baptist) (6/2/4)", 0.522, "#002554"],
      ["60. Hayden Hastings (Wyoming) (6/1/9)", 0.516, "#FFC425"],
      ["61. Matt Stencel (Central Michigan) (14/-/3)", 0.515, "#6A0032"],
    ],
  },
  {
    chartWrestlerSet: "wrestlers",
    chartDataSet: "mper",
    chartData: [
      ["Wrestler", "MPER", { role: "style" }],
      ["1. Spencer Lee (Iowa) (202-18)", 0.918, "#FFCD00"],
      ["2. Pat Glory (Princeton) (243-27)", 0.9, "#FF671F"],
      ["3. Bryce Steiert (Northern Iowa) (221-32)", 0.874, "#4B116F"],
      ["4. Cary Miller (App. State) (200-32)", 0.862, "#FFCC00"],
      ["5. Real Woods (Stanford) (133-23)", 0.853, "#8C1515"],
      ["6. Brock Hudkins (Indiana) (57-10)", 0.851, "#990000"],
      ["7. Luke Karam (Lehigh) (66-12)", 0.846, "#653819"],
      ["8. Hayden Hidlay (NC State) (235-44)", 0.842, "#CC0000"],
      ["9. Carson Kharchla (Ohio State) (107-21)", 0.836, "#BB0000"],
      ["10. Matthew Kolodzik (Princeton) (84-17)", 0.832, "#FF671F"],
      ["11. Jack Mueller (Virginia) (109-23)", 0.826, "#232D4B"],
      ["12. Ryan Deakin (Northwestern) (150-33)", 0.82, "#4E2A84"],
      ["13. Bennett Paulson (Army) (181-40)", 0.819, "#FFD530"],
      ["14. Tanner Sloan (SDSU) (173-41)", 0.808, "#0033A0"],
      ["15. Jordan Kutler (Lehigh) (147-35)", 0.808, "#653819"],
      ["16. Christian Robertson (Oregon State) (25-6)", 0.807, "#DC4405"],
      ["17. Jeren Glosser (Iowa) (62-15)", 0.805, "#FFCD00"],
      ["18. Nic Piccininni (OK State) (264-64)", 0.805, "#FF7300"],
      ["19. Boo Lewallen (OK State) (179-44)", 0.803, "#FF7300"],
      ["20. Shane Griffith (Stanford) (143-36)", 0.799, "#8C1515"],
      ["21. Mason Parris (Michigan) (160-41)", 0.796, "#00274C"],
      ["22. Nick Lee (Penn State) (220-58)", 0.791, "#093162"],
      ["23. Carter Starocci (Penn State) (136-36)", 0.791, "#093162"],
      ["24. Luke Weiland (Army) (115-31)", 0.788, "#FFD530"],
      ["25. Luke Werner (Lock Haven) (268-73)", 0.786, "#A50034"],
      ["26. Connor Flynn (Missouri) (259-73)", 0.78, "#F1B82D"],
      ["27. Vince Turk (Iowa) (84-24)", 0.778, "#FFCD00"],
      ["28. Josh Humphreys (Lehigh) (167-48)", 0.777, "#653819"],
      ["29. Seth Gross (Wisconsin) (295-86)", 0.774, "#C5050C"],
      ["30. Brian Andrews (Wyoming) (144-42)", 0.774, "#FFC425"],
      ["31. Jonathan Ross (Lock Haven) (345-101)", 0.774, "#A50034"],
      ["32. Colton Yapoujian (Cornell) (64-19)", 0.771, "#B31B1B"],
      ["33. Michael Beard (Penn State) (183-55)", 0.769, "#093162"],
      ["34. Derek Spann (Buffalo) (239-72)", 0.769, "#005BBB"],
      ["35. Mark Hall (Penn State) (121-37)", 0.766, "#093162"],
      ["36. Tony Cassioppi (Iowa) (100-31)", 0.763, "#FFCD00"],
      ["37. Devin Schroder (Purdue) (264-82)", 0.763, "#CEB888"],
      ["38. Drew Hildebrandt (Central Michigan) (159-50)", 0.761, "#6A0032"],
      ["39. Evan Wick (Wisconsin) (187-60)", 0.757, "#C5050C"],
      ["40. Ben Harvey (Army) (174-56)", 0.757, "#FFD530"],
      ["41. Cameron Sykora (NDSU) (204-67)", 0.753, "#0A5640"],
      ["42. Ben Darmstadt (Cornell) (140-46)", 0.753, "#B31B1B"],
      ["43. Sammy Sasso (Ohio State) (202-67)", 0.751, "#BB0000"],
      ["44. Nick Santos (Rutgers) (72-24)", 0.75, "#CC0033"],
      ["45. Christian Colucci (Rutgers) (39-13)", 0.75, "#CC0033"],
      ["46. Quentin Perez (Campbell) (248-83)", 0.749, "#EA7125"],
      ["47. Chandler Olson (Drexel) (95-32)", 0.748, "#003F87"],
      ["48. Michael Kemerer (Iowa) (163-55)", 0.748, "#FFCD00"],
      ["49. Kizhan Clarke (American) (304-103)", 0.747, "#ED193A"],
      ["50. Kollin Moore (Ohio State) (341-116)", 0.746, "#BB0000"],
      ["51. Trey Sizemore (Illinois) (38-13)", 0.745, "#13294B"],
      ["52. Jonathan Millner (App. State) (283-97)", 0.745, "#FFCC00"],
      ["53. Trent Hillger (Wisconsin) (148-51)", 0.744, "#C5050C"],
      ["54. Mickey Phillipi (Pittsburgh) (168-58)", 0.743, "#1C2957"],
      ["55. Kyle Shoop (Lock Haven) (239-83)", 0.742, "#A50034"],
      ["56. Lou Deprez (Binghamton) (203-71)", 0.741, "#005A43"],
      ["57. Noah Adams (West Virginia) (216-76)", 0.74, "#002855"],
      ["58. Alex Madrigal (George Mason) (338-119)", 0.74, "#006633"],
      ["59. Evan Cheek (Cleveland State) (230-81)", 0.74, "#006747"],
      ["60. Brady Barnett (Cleveland State) (85-30)", 0.739, "#006747"],
    ],
  },
  {
    chartWrestlerSet: "wrestlers",
    chartDataSet: "techfalls",
    chartData: [
      ["Wrestler", "Tech Falls", { role: "style" }],
      ["1. Alex Madrigal (George Mason) (0.429)", 14, "#006633"],
      ["2. Pat Glory (Princeton) (0.739)", 9, "#FF671F"],
      ["3. Spencer Lee (Iowa) (0.941)", 9, "#FFCD00"],
      ["4. Nic Piccininni (OK State) (0.692)", 9, "#FF7300"],
      ["5. Jonathan Ross (Lock Haven) (0.490)", 9, "#A50034"],
      ["6. Devin Schroder (Purdue) (0.483)", 8, "#CEB888"],
      ["7. Nick Lee (Penn State) (0.762)", 7, "#093162"],
      ["8. Seth Gross (Wisconsin) (0.655)", 7, "#C5050C"],
      ["9. Luke Werner (Lock Haven) (0.636)", 7, "#A50034"],
      ["10. Derek Spann (Buffalo) (0.500)", 7, "#005BBB"],
      ["11. Cameron Sykora (NDSU) (0.550)", 7, "#0A5640"],
      ["12. Bennett Paulson (Army) (0.619)", 7, "#FFD530"],
      ["13. Connor Flynn (Missouri) (0.483)", 7, "#F1B82D"],
      ["14. Devin Skatzka (Minnesota) (0.417)", 7, "#7A0019"],
      ["15. Codi Russell (App. State) (0.412)", 7, "#FFCC00"],
      ["16. Kollin Moore (Ohio State) (0.731)", 6, "#BB0000"],
      ["17. Neal Richards (VMI) (0.415)", 6, "#AE122A"],
      ["18. Austin DeSanto (Iowa) (0.600)", 6, "#FFCD00"],
      ["19. Quentin Perez (Campbell) (0.607)", 6, "#EA7125"],
      ["20. Michael Beard (Penn State) (0.600)", 6, "#093162"],
      ["21. Dakota Geer (OK State) (0.448)", 6, "#FF7300"],
      ["22. Jacob Koser (Navy) (0.321)", 6, "#00205B"],
      ["23. Hayden Hidlay (NC State) (0.714)", 5, "#CC0000"],
      ["24. Evan Cheek (Cleveland State) (0.429)", 5, "#006747"],
      ["25. Zach Carlson (SDSU) (0.484)", 5, "#0033A0"],
      ["26. Kyle Shoop (Lock Haven) (0.379)", 5, "#A50034"],
      ["27. Noah Stewart (Army) (0.391)", 5, "#FFD530"],
      ["28. Hunter Ladnier (Harvard) (0.484)", 5, "#A41034"],
      ["29. Rocky Jordan (Ohio State) (0.323)", 5, "#BB0000"],
      ["30. Cody Howard (Virginia Tech) (0.500)", 5, "#630031"],
      ["31. Bradley Irwin (App. State) (0.382)", 5, "#FFCC00"],
      ["32. Kevin Parker (Princeton) (0.409)", 5, "#FF671F"],
      ["33. Justin McCoy (Virginia) (0.310)", 5, "#232D4B"],
      ["34. Bryce Steiert (Northern Iowa) (0.679)", 4, "#4B116F"],
      ["35. Sammy Sasso (Ohio State) (0.500)", 4, "#BB0000"],
      ["36. Brandon Paetzell (Lehigh) (0.455)", 4, "#653819"],
      ["37. Dom Demas (Oklahoma) (0.310)", 4, "#841617"],
      ["38. Kizhan Clarke (American) (0.350)", 4, "#ED193A"],
      ["39. Troy Keller (Buffalo) (0.333)", 4, "#005BBB"],
      ["40. Brandon Courtney (Arizona State) (0.480)", 4, "#8C1D40"],
      ["41. Jarrett Jacques (Missouri) (0.286)", 4, "#F1B82D"],
      ["42. Mitch McKee (Minnesota) (0.500)", 4, "#7A0019"],
      ["43. Kennedy Monday (North Carolina) (0.321)", 4, "#7BAFD4"],
      ["44. Jeremiah Kent (Missouri) (0.458)", 4, "#F1B82D"],
      ["45. Anthony Madrigal (Oklahoma) (0.138)", 4, "#841617"],
      ["46. Gabriel Tagg (North Carolina) (0.539)", 4, "#7BAFD4"],
      ["47. Jonathan Loew (Cornell) (0.368)", 4, "#B31B1B"],
      ["48. Daniel Vega (SDSU) (0.407)", 4, "#0033A0"],
      ["49. Mikey Labriola (Nebraska) (0.464)", 4, "#E41C38"],
      ["50. Bernie Truax (Cal Poly) (0.308)", 4, "#003831"],
      ["51. Cole Urbas (Penn) (0.281)", 4, "#990000"],
      ["52. Cody Surratt (Air Force) (0.484)", 4, "#003087"],
      ["53. Robert Long (Navy) (0.300)", 4, "#00205B"],
      ["54. Anthony Lombardo (George Mason) (0.281)", 4, "#006633"],
    ],
  },
  {
    chartWrestlerSet: "wrestlers",
    chartDataSet: "falls",
    chartData: [
      ["Wrestler", "Falls", { role: "style" }],
      ["1. Jacob Schwarm (Northern Iowa) (2:04)", 16, "#4B116F"],
      ["2. Ben Darmstadt (Cornell) (1:42)", 14, "#B31B1B"],
      ["3. Matt Stencel (Central Michigan) (2:01)", 14, "#6A0032"],
      ["4. Tanner Cook (SDSU) (2:55)", 12, "#0033A0"],
      ["5. Cody Surratt (Air Force) (2:05)", 11, "#003087"],
      ["6. Noah Gosner (Campbell) (2:43)", 11, "#EA7125"],
      ["7. Denton Spencer (Virginia) (1:37)", 10, "#232D4B"],
      ["8. Mason Parris (Michigan) (1:59)", 10, "#00274C"],
      ["9. Matthew Waddell (Chattanooga) (2:01)", 10, "#00386B"],
      ["10. Randy Meneweather II (Air Force) (2:06)", 10, "#003087"],
      ["11. Josh Mason (Bloomsburg) (2:29)", 10, "#8E2344"],
      ["12. Mark Hall (Penn State) (3:02)", 10, "#093162"],
      ["13. Jason Kraisser (Campbell) (3:03)", 10, "#EA7125"],
      ["14. Colston DiBlasi (George Mason) (3:45)", 10, "#006633"],
      ["15. Bryce West (Northern Illinois) (1:42)", 9, "#BA0C2F"],
      ["16. Jonathan Ross (Lock Haven) (2:06)", 9, "#A50034"],
      ["17. Anthony Mantanona (Oklahoma) (2:39)", 9, "#841617"],
      ["18. Brandon Womack (Cornell) (2:43)", 9, "#B31B1B"],
      ["19. Spencer Berthhold (Kent State) (2:53)", 9, "#002664"],
      ["20. Greg Bulsak (Clarion) (2:55)", 9, "#003366"],
      ["21. Cameron Caffey (Michigan State) (3:17)", 9, "#18453B"],
      ["22. Mitch McKee (Minnesota) (1:56)", 8, "#7A0019"],
      ["23. Benjamin Root (American) (2:10)", 8, "#ED193A"],
      ["24. Alex Esposito (Rutgers) (2:25)", 8, "#CC0033"],
      ["25. Cameron Valdiviez (Missouri) (2:28)", 8, "#F1B82D"],
      ["26. Jaret Lane (Lehigh) (2:32)", 8, "#653819"],
      ["27. Matt Kazimir (Columbia) (2:45)", 8, "#003865"],
      ["28. Zach Hartman (Bucknell) (2:58)", 8, "#E87722"],
      ["29. Quinn Miller (Virginia) (2:59)", 8, "#232D4B"],
      ["30. Reece Heller (Hofstra) (3:34)", 8, "#003591"],
      ["31. Alex Marinelli (Iowa) (3:45)", 8, "#FFCD00"],
      ["32. Drew Hildebrandt (Central Michigan) (4:22)", 8, "#6A0032"],
      ["33. Ethan Karsten (American) (1:28)", 7, "#ED193A"],
      ["34. Cary Miller (App. State) (1:29)", 7, "#FFCC00"],
      ["35. Tony Cassioppi (Iowa) (1:56)", 7, "#FFCD00"],
      ["36. Kellen Ekern (Old Dominion) (2:29)", 7, "#003057"],
      ["37. Ryan Cloud (Rider) (2:42)", 7, "#981E32"],
      ["38. John McGarry (VMI) (3:08)", 7, "#AE122A"],
      ["39. Andrew Fogarty (NDSU) (3:26)", 7, "#0A5640"],
      ["40. Taylor Lujan (Northern Iowa) (3:28)", 7, "#4B116F"],
      ["41. Michael Burchell (App. State) (3:34)", 7, "#FFCC00"],
      ["42. Fischer Cian (Davidson) (3:53)", 7, "#AC1A2F"],
      ["43. Luke Werner (Lock Haven) (4:08)", 7, "#A50034"],
      ["44. Shane Griffith (Stanford) (4:26)", 7, "#8C1515"],
      ["45. Chad Red (Nebraska) (1:17)", 6, "#E41C38"],
      ["46. Mitch Moore (Virginia Tech) (1:36)", 6, "#630031"],
      ["47. Tanner Harvey (American) (1:45)", 6, "#ED193A"],
      ["48. Jere Heino (Campbell) (1:49)", 6, "#EA7125"],
      ["49. Hayden Hidlay (NC State) (1:52)", 6, "#CC0000"],
      ["50. Jonathan Loew (Cornell) (1:53)", 6, "#B31B1B"],
      ["51. Logan Brown (Army) (1:57)", 6, "#FFD530"],
      ["52. Alec Hagan (Ohio) (2:09)", 6, "#00694E"],
      ["53. Zach Schrader (Cal Baptist) (2:19)", 6, "#002554"],
      ["54. Evan Cheek (Cleveland State) (2:23)", 6, "#006747"],
      ["55. Landon Pelham (Central Michigan) (2:26)", 6, "#6A0032"],
      ["56. Evan Wick (Wisconsin) (2:35)", 6, "#C5050C"],
      ["57. Jackson Turley (Rutgers) (2:41)", 6, "#CC0033"],
      ["58. Kayne Hutchison (Air Force) (2:46)", 6, "#003087"],
      ["59. Tyler Eischens (Stanford) (2:47)", 6, "#8C1515"],
      ["60. Daniel Bullard (NC State) (2:49)", 6, "#CC0000"],
      ["61. Hayden Hastings (Wyoming) (2:54)", 6, "#FFC425"],
      ["62. Bryce Andonian (Virginia Tech) (2:56)", 6, "#630031"],
      ["63. Brock Bergelin (Central Michigan) (2:58)", 6, "#6A0032"],
      ["64. Spencer Trenary (SDSU) (2:59)", 6, "#0033A0"],
      ["65. Kyle Mosher (Columbia) (3:07)", 6, "#003865"],
      ["66. Jordie White (The Citadel) (3:09)", 6, "#3975B7"],
      ["67. Ebed Jarrell (Drexel) (3:16)", 6, "#003F87"],
      ["68. Joe Manchio (Columbia) (3:18)", 6, "#003865"],
      ["69. Sage Heller (Hofstra) (3:32)", 6, "#003591"],
      ["70. Bryan McLaughlin (Drexel) (3:33)", 6, "#003F87"],
      ["71. Brett Donner (Rutgers) (3:38)", 6, "#CC0033"],
      ["72. Austin O'Connor (North Carolina) (3:40)", 6, "#7BAFD4"],
    ],
  },
  {
    chartWrestlerSet: "team",
    chartDataSet: "falls",
    chartData: [
      ["Team", "Falls", { role: "style" }],
      ["1. Campbell (0.131)", 73, "#EA7125"],
      ["2. Lock Haven (0.112)", 67, "#A50034"],
      ["3. Army (0.096)", 66, "#FFD530"],
      ["4. Cornell (0.133)", 64, "#B31B1B"],
      ["5. Virginia (0.105)", 59, "#232D4B"],
      ["6. App. State (0.084)", 56, "#FFCC00"],
      ["7. Penn State (0.140)", 55, "#093162"],
      ["8. Lehigh (0.109)", 55, "#653819"],
      ["9. Central Michigan (0.108)", 55, "#6A0032"],
      ["10. Rutgers (0.109)", 52, "#CC0033"],
      ["11. American (0.103)", 51, "#ED193A"],
      ["12. Air Force (0.115)", 49, "#003087"],
      ["13. Northern Iowa (0.116)", 46, "#4B116F"],
      ["14. Virginia Tech (0.100)", 45, "#630031"],
      ["15. Stanford (0.138)", 44, "#8C1515"],
      ["16. Minnesota (0.100)", 44, "#7A0019"],
      ["17. Navy (0.085)", 44, "#00205B"],
      ["18. Michigan State (0.084)", 44, "#18453B"],
      ["19. SDSU (0.089)", 43, "#0033A0"],
      ["20. Missouri (0.089)", 43, "#F1B82D"],
      ["21. Maryland (0.082)", 43, "#E03A3E"],
      ["22. NC State (0.081)", 42, "#CC0000"],
      ["23. Nebraska (0.112)", 40, "#E41C38"],
      ["24. Chattanooga (0.102)", 40, "#00386B"],
      ["25. Penn (0.069)", 39, "#990000"],
      ["26. Kent State (0.074)", 38, "#002664"],
      ["27. Old Dominion (0.057)", 38, "#003057"],
      ["28. Ohio State (0.079)", 37, "#BB0000"],
      ["29. Rider (0.065)", 37, "#981E32"],
      ["30. Iowa (0.129)", 36, "#FFCD00"],
      ["31. N. Colorado (0.099)", 36, "#013C65"],
      ["32. Clarion (0.088)", 36, "#003366"],
      ["33. Iowa State (0.069)", 36, "#C8102E"],
      ["34. Bucknell (0.069)", 35, "#E87722"],
      ["35. Wisconsin (0.091)", 34, "#C5050C"],
      ["36. North Carolina (0.073)", 34, "#7BAFD4"],
      ["37. Hofstra (0.102)", 33, "#003591"],
      ["38. Cleveland State (0.075)", 33, "#006747"],
      ["39. Bloomsburg (0.103)", 32, "#8E2344"],
      ["40. Princeton (0.089)", 32, "#FF671F"],
      ["41. Columbia (0.087)", 32, "#003865"],
      ["42. Drexel (0.087)", 32, "#003F87"],
      ["43. West Virginia (0.068)", 31, "#002855"],
      ["44. Michigan (0.081)", 30, "#00274C"],
      ["45. Franklin & Marshall (0.079)", 30, "#0035AD"],
      ["46. Oklahoma (0.072)", 30, "#841617"],
      ["47. George Mason (0.066)", 30, "#006633"],
      ["48. Arizona State (0.092)", 28, "#8C1D40"],
      ["49. The Citadel (0.072)", 28, "#3975B7"],
      ["50. Ohio (0.059)", 28, "#00694E"],
      ["51. Davidson (0.104)", 27, "#AC1A2F"],
      ["52. Northwestern (0.075)", 27, "#4E2A84"],
      ["53. Wyoming (0.070)", 27, "#FFC425"],
      ["54. NDSU (0.075)", 26, "#0A5640"],
      ["55. VMI (0.064)", 26, "#AE122A"],
      ["56. Utah Valley (0.081)", 25, "#275D38"],
      ["57. CSU Bakersfield (0.068)", 25, "#005DAA"],
      ["58. Purdue (0.064)", 25, "#CEB888"],
      ["59. OK State (0.077)", 24, "#FF7300"],
      ["60. Oregon State (0.074)", 24, "#DC4405"],
      ["61. Gardner-Webb (0.060)", 24, "#BF2F37"],
    ],
  },
  {
    chartWrestlerSet: "team",
    chartDataSet: "techfalls",
    chartData: [
      ["Team", "Tech Falls", { role: "style" }],
      ["1. Lock Haven (0.075)", 45, "#A50034"],
      ["2. App. State (0.054)", 36, "#FFCC00"],
      ["3. Penn State (0.089)", 35, "#093162"],
      ["4. Army (0.050)", 34, "#FFD530"],
      ["5. George Mason (0.073)", 33, "#006633"],
      ["6. Missouri (0.066)", 32, "#F1B82D"],
      ["7. OK State (0.100)", 31, "#FF7300"],
      ["8. NC State (0.058)", 30, "#CC0000"],
      ["9. Ohio State (0.062)", 29, "#BB0000"],
      ["10. Princeton (0.075)", 27, "#FF671F"],
      ["11. Virginia (0.048)", 27, "#232D4B"],
      ["12. Iowa (0.093)", 26, "#FFCD00"],
      ["13. Penn (0.046)", 26, "#990000"],
      ["14. Lehigh (0.050)", 25, "#653819"],
      ["15. Cornell (0.048)", 23, "#B31B1B"],
      ["16. Navy (0.044)", 23, "#00205B"],
      ["17. Minnesota (0.050)", 22, "#7A0019"],
      ["18. Nebraska (0.059)", 21, "#E41C38"],
      ["19. SDSU (0.044)", 21, "#0033A0"],
      ["20. Wisconsin (0.054)", 20, "#C5050C"],
      ["21. Virginia Tech (0.042)", 19, "#630031"],
      ["22. Arizona State (0.059)", 18, "#8C1D40"],
      ["23. Columbia (0.049)", 18, "#003865"],
      ["24. Buffalo (0.034)", 18, "#005BBB"],
      ["25. Michigan State (0.034)", 18, "#18453B"],
      ["26. Campbell (0.032)", 18, "#EA7125"],
      ["27. North Carolina (0.037)", 17, "#7BAFD4"],
      ["28. Air Force (0.038)", 16, "#003087"],
      ["29. Stanford (0.047)", 15, "#8C1515"],
      ["30. Chattanooga (0.038)", 15, "#00386B"],
      ["31. Central Michigan (0.030)", 15, "#6A0032"],
      ["32. Maryland (0.029)", 15, "#E03A3E"],
      ["33. NDSU (0.040)", 14, "#0A5640"],
      ["34. Purdue (0.036)", 14, "#CEB888"],
      ["35. Edinboro (0.034)", 14, "#BB131A"],
      ["36. West Virginia (0.031)", 14, "#002855"],
      ["37. Old Dominion (0.021)", 14, "#003057"],
      ["38. Fresno State (0.032)", 13, "#DB0032"],
      ["39. Rutgers (0.027)", 13, "#CC0033"],
      ["40. American (0.026)", 13, "#ED193A"],
      ["41. Oregon State (0.037)", 12, "#DC4405"],
      ["42. N. Colorado (0.033)", 12, "#013C65"],
      ["43. VMI (0.030)", 12, "#AE122A"],
      ["44. Clarion (0.029)", 12, "#003366"],
      ["45. Oklahoma (0.029)", 12, "#841617"],
      ["46. Iowa State (0.023)", 12, "#C8102E"],
      ["47. Hofstra (0.034)", 11, "#003591"],
      ["48. Brown (0.032)", 11, "#4E3629"],
      ["49. Drexel (0.030)", 11, "#003F87"],
      ["50. Cleveland State (0.025)", 11, "#006747"],
      ["51. Kent State (0.021)", 11, "#002664"],
      ["52. Cal Poly (0.038)", 10, "#003831"],
      ["53. Harvard (0.030)", 10, "#A41034"],
      ["54. Northern Iowa (0.025)", 10, "#4B116F"],
      ["55. Ohio (0.021)", 10, "#00694E"],
    ],
  },
]

const fmtFourBars = [
  {
    type: "NumberFormat",
    column: 1,
    options: {
      // prefix: '$',
      negativeColor: "red",
      negativeParens: false,
      fractionDigits: 3,
    },
  },
  {
    type: "NumberFormat",
    column: 2,
    options: {
      // prefix: '$',
      negativeColor: "red",
      negativeParens: false,
      fractionDigits: 3,
    },
  },
  {
    type: "NumberFormat",
    column: 3,
    options: {
      // prefix: '$',
      negativeColor: "red",
      negativeParens: false,
      fractionDigits: 3,
    },
  },
  {
    type: "NumberFormat",
    column: 4,
    options: {
      // prefix: '$',
      negativeColor: "red",
      negativeParens: false,
      fractionDigits: 3,
    },
  },
]

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_WRESTLER_SET":
      return {
        ...state,
        isTeamViewOn: !state.isTeamViewOn,
        wrestlerSet: state.isTeamViewOn ? "wrestlers" : "team",
        dataSet:
          !state.isTeamViewOn &&
          (state.dataSet === "mper") |
            (state.dataSet === "dominance") |
            (state.dataSet === "bonus")
            ? "falls"
            : state.dataSet,
        chartData: getChartData(
          state.isTeamViewOn ? "wrestlers" : "team",
          !state.isTeamViewOn &&
            (state.dataSet === "mper") |
              (state.dataSet === "dominance") |
              (state.dataSet === "bonus")
            ? "falls"
            : state.dataSet
        ),
        chartTitle: getChartTitle(
          !state.isTeamViewOn &&
            (state.dataSet === "mper") |
              (state.dataSet === "dominance") |
              (state.dataSet === "bonus")
            ? "falls"
            : state.dataSet,
          state.isTeamViewOn ? "wrestlers" : "team"
        ),
        chartBarColor: getBarColor(
          !state.isTeamViewOn &&
            (state.dataSet === "mper") |
              (state.dataSet === "dominance") |
              (state.dataSet === "bonus")
            ? "falls"
            : state.dataSet,
          state.isTeamViewOn ? "wrestlers" : "team"
        ),
      }
    case "CHANGE_DATA_SET":
      return {
        ...state,
        dataSet: action.item,
        wrestlerSet: state.isTeamViewOn ? "team" : "wrestlers",
        chartData: getChartData(
          state.isTeamViewOn ? "team" : "wrestlers",
          action.item
        ),
        chartTitle: getChartTitle(
          action.item,
          state.isTeamViewOn ? "team" : "wrestlers"
        ),
        chartBarColor: getBarColor(
          action.item,
          state.isTeamViewOn ? "team" : "wrestlers"
        ),
      }
    default:
      return state
  }
}

const initialState = {
  subHeadText: "Your Individual NCAA D1 Fall Leaders",
  chartTitle: defaultChartTitle,
  chartBarColor: "#16609f",
  chartData: defaultChartData,
  wrestlerSet: "wrestlers",
  dataSet: "falls",
  isTeamViewOn: false,
  barFormatSyntax: fmtFourBars,
  chartHeight: isMobile ? getPixelValue(500) : getPixelValue(1400),
  chartWidth: isMobile ? 600 : 1600,
}

function CauliContainerCharts() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const asOfDate = "08-Mar-2020"

  useEffect(() => {
    loadCSS(
      "https://fonts.googleapis.com/icon?family=Material+Icons",
      document.querySelector("#insertion-point-jss")
    )
    return () => {}
  }, [])

  const toggleTeamView = () => {
    dispatch({ type: "CHANGE_WRESTLER_SET" })
  }

  const changeDataSet = item => {
    dispatch({ type: "CHANGE_DATA_SET", item })
  }

  return (
    <div>
      {/* {console.log("page load - chartWidth: " + state.chartWidth)} */}
      <Container className={hodgeContainerStyling.raisedBackground}>
        <>
          <Row>
            <Col fluie="true">
              <Form>
                <span className={layoutStyles.heading}>
                  {/* <Form.Check
                    type="switch"
                    id="team-switcher"
                    tabIndex="0"
                    onChange={toggleTeamView}
                    checked={state.isTeamViewOn}
                    label={state.isTeamViewOn ? "TEAM" : "WRESTLER"}
                  />{" "} */}
                  <Form.Check
                    type="switch"
                    id="wrestler-switcher"
                    inline
                    tabIndex="0"
                    onChange={toggleTeamView}
                    checked={!state.isTeamViewOn}
                    label="WRESTLER"
                  />{" "}
                  <Form.Check
                    type="switch"
                    id="team-switcher"
                    inline
                    tabIndex="0"
                    onChange={toggleTeamView}
                    checked={state.isTeamViewOn}
                    label="TEAM"
                  />{" "}
                </span>
              </Form>{" "}
            </Col>
            {/* <Col sm={1}></Col> */}
            <Col fluid="md" lg={8} xl={8}>
              {state.isTeamViewOn ? (
                // <p>still building</p>
                // teamStatTypes
                <Form>
                  <div key="chartSelector" className="mb-3">
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        <span className={layoutStyles.heading}>
                          TEAM STATS:
                        </span>
                      </Form.Label>{" "}
                      <Col sm="7">
                        <Form.Control
                          id="teamStats"
                          tabIndex="0"
                          as="select"
                          value={state.dataSet}
                          onChange={event => changeDataSet(event.target.value)}
                        >
                          {teamStatTypes.map(statType => (
                            <option key={statType.value} value={statType.value}>
                              {statType.display}
                            </option>
                          ))}
                        </Form.Control>{" "}
                      </Col>
                    </Form.Group>
                  </div>
                </Form>
              ) : (
                <Form>
                  <div key="chartSelector" className="mb-3">
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        <span className={layoutStyles.heading}>
                          WRESTLER STATS:
                        </span>
                      </Form.Label>{" "}
                      <Col sm="6">
                        <Form.Control
                          id="wrestlerStats"
                          as="select"
                          value={state.dataSet}
                          onChange={event => changeDataSet(event.target.value)}
                        >
                          {wrestlerStatTypes.map(statType => (
                            <option key={statType.value} value={statType.value}>
                              {statType.display}
                            </option>
                          ))}
                        </Form.Control>{" "}
                      </Col>
                    </Form.Group>
                  </div>
                </Form>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <span className={hodgeContainerStyling.smallText}>
                Stats as of {asOfDate} by{" "}
                <OutboundLink href="https://linktr.ee/wrestling_friend">
                  {" "}
                  your Wrestling Friend
                </OutboundLink>
              </span>
              <br />
              <span className={hodgeContainerStyling.smallText}>
                Data Courtesy of{" "}
                <OutboundLink href="https://wrestlingbypirate.wordpress.com/?s=Dual+Impact+Index">
                  {" "}
                  Wrestling by Pirate
                </OutboundLink>
              </span>
            </Col>
          </Row>
        </>{" "}
      </Container>
      <>
        <br />
        <div
          id="cauliCharts"
          className={hodgeContainerStyling.raisedBackground}
        >
          <h6 className={layoutStyles.heading}>{state.chartTitle} </h6>
          {/* {setChartComponent()} */}
          {/* <h6>{state.subHeadText}</h6> */}
          <br />
          <Row>
            <Col xs={12} sm={12} lg={10} xl={10} md={12}>
              <>
                {/* {console.log("data set: " + state.chartDataSet)} */}
                <Chart
                  chartType="BarChart"
                  loader={<div>Loading Chart</div>}
                  data={state.chartData}
                  options={{
                    title: state.chartTitle,
                    colors: [state.chartBarColor],
                    chartArea: { height: "90%" },
                    // width: 1000,
                    width: state.chartWidth,
                    height: 1600,
                    bar: { groupWidth: "95%" },
                    legend: { position: "none" },
                    fontName: "Open Sans",
                    fontSize: isMobile
                      ? 10
                      : isMobile && state.wrestlerSet === "team"
                      ? 12
                      : !isMobile && state.wrestlerSet === "team"
                      ? 16
                      : 12,
                    hAxis: {
                      minValue:
                        state.dataSet === "dominance"
                          ? 0
                          : state.dataSet === "mper"
                          ? 0.575
                          : 0,
                    },
                    axes: {
                      x: {
                        0: { side: "top" }, // Top x-axis.
                      },
                    },
                  }}
                  // For tests
                  rootProps={{ "data-testid": "6" }}
                />{" "}
              </>
            </Col>
          </Row>
          <br />
        </div>
      </>{" "}
    </div>
  )
}

export default CauliContainerCharts
