import React from "react"
import logo from "../../../static/logos/3DWS_LogoBlue_64.png"
import Container from "react-bootstrap/Container"
import Media from "react-bootstrap/Media"
import { shuffle } from "underscore"
import quoteBoxStyling from "./quotebox.module.scss"

const quotes = [
  {
    quote:
      "I was able to use the stats analysis to make a line up change today. We didn't win the dual because of it, but we picked up a really strong individual win from the change.",
    by: "Coach Mike DeRoehn",
  },
  {
    quote:
      "I love the time I save and seeing rich details about each wrestler during the season.",
    by: "Coach Jeff Humpherys",
  },
  {
    quote:
      "The analysis available here is great. It's amazing how much these stats can teach you about your wrestlers in just a few minutes.",
    by: "Coach Scott Pace",
  },
  {
    quote:
      "What I love is getting back to coaching & designing practices I know will help our wrestlers, not to mention spending more time with my family. 3D Wrestler Stats lays out wrestling stats in an easy to read format for my team & for each individual wrestler, saving me hours of time and paperwork as well as providing valuable insights into each wrestler's performance.",
    by: "Coach Jeff Humpherys",
  },
  {
    quote:
      "It's great to see the strengths and weakness for each wrestler in each wrestling position listed out in such an easy to read format. This along with the team scorecard makes it easy to know where to place our emphasis when designing practices and working with individual wrestlers.",
    by: "Coach Scott Pace",
  },
]

function getCoachesQuote() {
  return shuffle(quotes).slice(0, 1)
}

function QuoteBox() {
  const quote = getCoachesQuote()
  return (
    <div>
      <br />
      <Container>
        <Media
          style={{
            backgroundColor: "#343a40",
            color: "white",
            borderRadius: "15px",
            padding: "20px",
          }}
        >
          <a
            className="lead"
            href="https://3d-wrestler-stats.square.site/s/shop"
            alt="3D Wrestler Stats Packages"
          >
            <img
              width={64}
              height={64}
              className="mr-3"
              src={logo}
              alt="3D Wrestler Stats"
            />
          </a>
          <Media.Body>
            <q className={quoteBoxStyling}>{quote[0].quote}</q>
            <br />
            <h5>{quote[0].by}</h5>
          </Media.Body>
        </Media>{" "}
      </Container>
      <br />
    </div>
  )
}

export default QuoteBox
