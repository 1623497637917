import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import { OutboundLink } from "gatsby-plugin-gtag"
import { FaTwitter as IconTwitter } from "react-icons/fa"

import indexStyling from "../../pages/index.module.scss"
import layoutStyles from "../../components/layout/layout.module.scss"

function CauliBadge() {
  const imageQuery = useStaticQuery(graphql`
    query {
      wFriendpLogo: file(name: { regex: "/partnerLogo_wrestlingFriend/gi" }) {
        name
        childImageSharp {
          fluid {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
      wPiratepLogo: file(name: { regex: "/partnerLogo_wrestlingbyPirate/gi" }) {
        name
        childImageSharp {
          fluid {
            base64
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
  `)

  return (
    <div>
      <br />
      <Container className={indexStyling.raisedContainer}>
        <Row>
          <Col xs={2} sm={2} lg={2} xl={2} md={2}>
            <Link to="/wrestling-stats/ncaa-d1-wrestling-stats-leaders/">
              <Img
                fluid={imageQuery.wFriendpLogo.childImageSharp.fluid}
                alt="Your Wrestling Friend"
              >
                {" "}
              </Img>
            </Link>
          </Col>
          <Col xs={8} sm={8} lg={8} xl={8} md={8}>
            <br />
            <h5 className={layoutStyles.heading}>
              PROUD TO HAVE{" "}
              <Link to="/wrestling-stats/ncaa-d1-wrestling-stats-leaders/">
                PARTNERED
              </Link>{" "}
              WITH YOUR WRESTLING FRIEND:
            </h5>
            <ul>
              <li>
                See his Leader Boards here on 3D Wrestler Stats:{" "}
                <Link to="/wrestling-stats/ncaa-d1-wrestling-stats-leaders/">
                  D1 Wrestling Leader Boards
                </Link>{" "}
              </li>
              <li>
                Check out his Full{" "}
                <OutboundLink href="https://linktr.ee/wrestling_friend">
                  D1 Wrestling Advanced Stats & Analytics pages
                </OutboundLink>{" "}
                or find him on{" "}
                <OutboundLink href="https://twitter.com/CauliFriend">
                  Twitter <IconTwitter />
                </OutboundLink>{" "}
              </li>
              <li>
                <b>Data Courtesy of</b>:{" "}
                <OutboundLink href="https://twitter.com/wrestlingbypir8">
                  Wrestling by Pir8's <IconTwitter />
                </OutboundLink>{" "}
                fine work on{" "}
                <OutboundLink href="https://wrestlingbypirate.wordpress.com/?s=Dual+Impact+Index">
                  Dual Impact Ratings
                </OutboundLink>
              </li>
            </ul>
          </Col>
          <Col xs={2} sm={2} lg={2} xl={2} md={2}>
            <Link to="/wrestling-stats/ncaa-d1-wrestling-stats-leaders/">
              <Img
                fluid={imageQuery.wPiratepLogo.childImageSharp.fluid}
                alt="Your Wrestling Friend"
              >
                {" "}
              </Img>
            </Link>
          </Col>
        </Row>
      </Container>
      <br />
    </div>
  )
}

export default CauliBadge
