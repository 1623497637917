// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-3-dws-js": () => import("./../../../src/pages/3dws.js" /* webpackChunkName: "component---src-pages-3-dws-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-blog-search-js": () => import("./../../../src/pages/blog/blog-search.js" /* webpackChunkName: "component---src-pages-blog-blog-search-js" */),
  "component---src-pages-blog-hodge-hodge-trophy-wrestling-blog-js": () => import("./../../../src/pages/blog/hodge/hodge-trophy-wrestling-blog.js" /* webpackChunkName: "component---src-pages-blog-hodge-hodge-trophy-wrestling-blog-js" */),
  "component---src-pages-blog-wrestling-stats-wrestling-stats-blog-js": () => import("./../../../src/pages/blog/wrestling-stats/wrestling-stats-blog.js" /* webpackChunkName: "component---src-pages-blog-wrestling-stats-wrestling-stats-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-wrestling-stats-hodge-charts-js": () => import("./../../../src/pages/wrestling-stats/hodge-charts.js" /* webpackChunkName: "component---src-pages-wrestling-stats-hodge-charts-js" */),
  "component---src-pages-wrestling-stats-hodge-stats-hodge-trophy-wrestling-stats-js": () => import("./../../../src/pages/wrestling-stats/hodge-stats/hodge-trophy-wrestling-stats.js" /* webpackChunkName: "component---src-pages-wrestling-stats-hodge-stats-hodge-trophy-wrestling-stats-js" */),
  "component---src-pages-wrestling-stats-hodge-stats-js": () => import("./../../../src/pages/wrestling-stats/hodge-stats.js" /* webpackChunkName: "component---src-pages-wrestling-stats-hodge-stats-js" */),
  "component---src-pages-wrestling-stats-hodge-stats-mobile-view-hodge-mobile-tables-js": () => import("./../../../src/pages/wrestling-stats/hodge-stats/mobile-view/hodge-mobile-tables.js" /* webpackChunkName: "component---src-pages-wrestling-stats-hodge-stats-mobile-view-hodge-mobile-tables-js" */),
  "component---src-pages-wrestling-stats-hodge-stats-stats-view-hodge-stats-tables-js": () => import("./../../../src/pages/wrestling-stats/hodge-stats/stats-view/hodge-stats-tables.js" /* webpackChunkName: "component---src-pages-wrestling-stats-hodge-stats-stats-view-hodge-stats-tables-js" */),
  "component---src-pages-wrestling-stats-ncaa-d-1-wrestling-stats-leaders-js": () => import("./../../../src/pages/wrestling-stats/ncaa-d1-wrestling-stats-leaders.js" /* webpackChunkName: "component---src-pages-wrestling-stats-ncaa-d-1-wrestling-stats-leaders-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

