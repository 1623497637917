import React from "react"
import { Title, Link, Meta } from "react-head"
import { useStaticQuery, graphql } from "gatsby"

const Head = ({ pageTitle, pageDescription }) => {
  const siteData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  return (
    <>
      <Title>{`${pageTitle} | ${siteData.site.siteMetadata.title}`}</Title>
      <Meta name="title" content={pageTitle} />
      <Meta name="description" content={pageDescription} />
      <Meta
        name="keywords"
        content="Wrestling,Stats,Data Analytics,Hodge Trophy,NCAA Wrestling, Collegiate Wrestling,Machine Learning, Artificial Intelligence, Wrestling Stats,USA Wrestling"
      />
      <Link rel="canonical" content="https://3dwrestlerstats.com/" />
    </>
  )
}

export default Head
