import React from "react"
import Footer from "../navigation/footer"
import Header from "../navigation/header"
import layoutStyles from "./layout.module.scss"
import "bootstrap/dist/css/bootstrap.css"
import { setDefaultBreakpoints } from "react-socks"
import "fontsource-russo-one"
import "fontsource-kirang-haerang"
import "fontsource-mclaren"

const Layout = props => {
  setDefaultBreakpoints([
    { xs: 200 },
    { s: 700 },
    { m: 800 },
    { l: 900 },
    { xl: 1200 },
  ])

  return (
    <div>
      <Header />
      <div className={layoutStyles.container}>{props.children}</div>
      <br />
      <Footer />
    </div>
  )
}

export default Layout
