import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import layoutStyles from "../../components/layout/layout.module.scss"
import CauliBadge from "../../components/common/caulibadge"

const CauliFootnotes = () => {
  return (
    <div>
      <br />
      <br />
      <h4 className={layoutStyles.heading}>
        FRIENDLY WRESTLING STATS FOOTNOTES
      </h4>
      <p className="lead">
        This page is just getting started. Let us know if you see any room for
        improvement!
      </p>
      <h5 className={layoutStyles.heading}>*STATS DEFINITIONS:</h5>
      <ul>
        <li>These numbers include matches against D1 competition only</li>
        <li>
          Bonus Rate = (Bonus Wins) / (Possible Bonus Wins, i.e., Matches where
          bonus is possible). So, the formula works out like this: (Wins by MD,
          FALL, TF, DQ) / (Wins and Losses by DEC, MD, FALL, TF, DQ). This bonus
          rate basically counts DQs as bonus wins and excludes FFs, MFFs, and
          Inj Defaults from “possible bonus wins”.
        </li>
        <li>
          How do we Break the Ties -- Secondary criteria is used, such as win %
          or average fall time, number of bonus wins, etc..{" "}
        </li>
        <li>
          Matches Analyzed -- matches wrestles include those that count towards
          NCAA Tournament seeding and are considered for end of season awards.
          Matches against non-D1 opponents are excluded for example. Matches for
          Wrestlers in Red-shirt count towards Roster totals.{" "}
        </li>
        <li>
          Threshold -- A minimum number of matches is required. The minimum
          thresholds adjust upwards as the season goes on.{" "}
        </li>
        <li>
          Point Efficiency -- based on non-fall matches as traditionally, when a
          fall is recorded, the match points scored up until that point are not
          recorded.
        </li>
        <li>
          For Point Efficiency -- The total points scored by the wrestler
          compared to the total points scored by opponents are included in
          parentheses. Given that points are not recorded for matches ending in
          a fall, a winning record is required for inclusion in this category.
        </li>
      </ul>
      <br />
      <Row>
        <Col lg={12} xs={12}>
          <CauliBadge />
        </Col>{" "}
      </Row>
      <p></p>
    </div>
  )
}

export default CauliFootnotes
