import React from "react"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout/layout"
import Head from "../components/layout/head"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-gtag"
import layoutStyles from "../components/layout/layout.module.scss"
import LockOpenIcon from "@material-ui/icons/LockOpen"

const About = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      file(name: { regex: "/Unleash/" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Head
        pageTitle="About"
        pageDescription="Information and Background About 3D Wrestler Stats and the Data Analytics Tools it Provides."
      />
      <h1 className={layoutStyles.heading}>ABOUT</h1>
      <br />
      <Row>
        <Col lg={6} xs={12} className="p-3">
          <Card bg="light" border="info">
            <Card.Body>
              <h4>WHAT YOU RECEIVE</h4>
              <span className="lead">
                <ul>
                  <li>
                    Advanced Data Analytics for your College or High School
                    Wrestling Team
                  </li>
                  <li>Positional Power Ratings (using: WAPRS&copy;)</li>
                  <li>Individual Wrestler Details</li>
                  <li>
                    Team Performance Analysis using advanced data analytics
                    tools
                  </li>
                </ul>
              </span>{" "}
              <h4>
                ROUND-by-ROUND <Link to="/pricing">SERVICES</Link>
              </h4>
              <span className="lead">
                <ul>
                  <li>
                    Works as long as match action details are recorded in a
                    TrackWrestling OPC Season or in Flowrestling tournaments
                    available on FloArena.
                  </li>
                  <li>
                    Team stat sheets may also work, but this is a secondary
                    option.
                  </li>
                </ul>
              </span>{" "}
              <h4>PRE-REQUISITES (for AGGREGATE SERVICES)</h4>
              <span className="lead">
                <ul>
                  <li>
                    You must have the TrackWrestling Statistics package for the
                    College or HS season you would like us to analyze. And
                    matches, including match action, must be entered into the
                    TrackWrestling season for your team (either from tournaments
                    run by TrackWrestling, MatBoss or LevelChanger video
                    analysis that has been uploaded to TrackWrestling, or
                    matches entered manually via the TrackWrestling Statistics
                    program. Our video explains more on this aspect of data
                    entry). Flowrestling tournaments only provide match outcome
                    details to TrackWrestling.
                  </li>
                  <li>
                    3D Wrestler stats will be your Virtual Statistician*! (we'll
                    send instructions on adding 3D Wrestler Stats as a
                    statistician once you've signed-up)
                  </li>
                  <li>
                    NOTE: At the Aggregate level, the 3D Wrestler Wrestling
                    Analytics Power Rating System (WAPRS&copy;) tools only
                    analyze wrestler performance for matches entered with match
                    action details (takedowns, by whom, choice, by whom, etc.).
                    These details can be modified via TrackWrestling OPC by your
                    local team statistician even after the fact for HS teams and
                    there may be opportunity withing NCAA guidelines to do this
                    for college teams, but cannot be modified by the 3D Wrestler
                    Stats team.
                  </li>
                </ul>
              </span>{" "}
              <h4>HOW WE GOT STARTED</h4>
              <span className="lead">
                <ul>
                  <li>
                    I've spent 20 years developing predictive analytics tools to
                    monitor software performance.
                  </li>
                  <li>
                    After I started helping my son's wrestling team with their
                    stats --- I kind of semi-automatically started building the
                    same types of Analytics Tools for his team. The result is 3D
                    Wrestler Stats.
                  </li>
                </ul>
              </span>{" "}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} xs={12} className="p-3">
          {" "}
          <Card bg="light" border="info">
            <Card.Body className="p-3">
              <OutboundLink href="https://3d-wrestler-stats.square.site/s/shop">
                {" "}
                <Img
                  fluid={imageQuery.file.childImageSharp.fluid}
                  alt="Unlock Your Potential"
                >
                  {" "}
                </Img>
              </OutboundLink>
              <footer>
                <Link to="/pricing">
                  <LockOpenIcon />{" "}
                  <small className="text-muted">
                    Unlock Your Wrestler's Potential Today!
                  </small>
                </Link>
              </footer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}

export default About
